<template>
	<tr>
		<td
			class='text-left"'
			:class="isAlarm ? 'text-danger' : ''">
			{{ $t_readings(`table_columns.${keyLang}`) }}
		</td>
		<td :class="isAlarm ? 'text-danger' : ''">
			{{ valueString }}
		</td>
	</tr>
</template>

<script>
export default {
	name: 'DetailItem',
	props: {
		current_reading: {required: true},
		reading_key: {required: true},
	},
	computed: {
		keyLang(){
			switch (this.reading_key) {
				case 'ac_temperature':
					return 'temperature_ac'
				case 'af_temperature':
					return 'temperature_af'
				default:
					return this.reading_key
			}
		},
		valueString(){
			const booleans = [
				'cleaning',
				'maintenance',
				'disinfection',
				'purge'
			]
			if(booleans.includes(this.reading_key)){
				return this.current_reading[this.reading_key] ? 'Si' : 'No'
			}
			return this.current_reading[this.reading_key] || 'X'
		},
		isAlarm() {
			return this.current_reading.hasAlarm && this.current_reading.alarms.includes(this.reading_key)
		},
	},
}
</script>
