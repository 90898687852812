require('./bootstrap')
import locale from './locale'
import Alpine from 'alpinejs'
import DisableAutocomplete from 'vue-disable-autocomplete'

window.Vue = require('vue').default
const store = require('./store').default

//listen changes lang session
locale.listen()

require('./components/common_components')

window.Alpine = Alpine
Alpine.start()

// Vue.use(DisableAutocomplete)

require('./components')

//Load modules
function loadModuleComponentsFile(module) {
	require(`../../Modules/${module}/Resources/assets/js/app`)
}

loadModuleComponentsFile('Admin')
loadModuleComponentsFile('Profile')
loadModuleComponentsFile('Events')
loadModuleComponentsFile('Attachments')
loadModuleComponentsFile('Customers')
loadModuleComponentsFile('Installations')
loadModuleComponentsFile('Tasks')
loadModuleComponentsFile('Readings')
loadModuleComponentsFile('Alarms')
loadModuleComponentsFile('Reports')


if ($('#app').length) {
	const app = new Vue({
		el: '#app',
		store
	})
}

Vue.component('footer-component', require('./components/Footer.vue').default)

if ($('#footer').length) {
	const footer = new Vue({
		el: '#footer',
		store
	})
}
