<template>
	<div>
		<div class='row mb-3'>
			<reading-type-combo class="col-12 col-lg-6" @selected='setAttribute' :current='current_attribute' />
			<div class='col-4 '>
				<button @click='appendCondition' class='btn btn-success justify-end btn-xs'>
					<i class='fa fa-plus'></i> {{ this.$t('Consigns.add_condition') }}
				</button>
			</div>
		</div>
		<div class='row mt-4'>
			<div v-for='(condition,i) in conditionals' :key='i' class='col-12'>
				<ConsignEvaluate
					:index='i'
					:item='condition'
					@remove='removeConditional'
					@set='setItem'
				/>
				<hr class='my-2'>
			</div>

		</div>
		<button @click='save' v-if='conditionals.length > 0' class='btn btn-primary btn-sm'>
			{{ this.$t('Consigns.add_consign') }}
		</button>

	</div>
</template>
<script>
import ReadingTypeCombo from '@modules/Readings/Resources/assets/components/ReadingTypeCombo.vue'
import ConsignEvaluate from './ConsignEvaluates'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
	name: 'ConsignsForm',
	props: {
		setted: {required: false},
	},
	components: {
		ReadingTypeCombo,
		vSelect,
		ConsignEvaluate,
	},
	watch: {
		setted: function() {
			this.current_attribute = null
		},
	},
	created() {
	},
	data() {
		return {
			current_attribute: null,
			conditionals: [],
		}
	},
	methods: {
		setAttribute(value) {
			if (!value || (this.current_attribute && this.current_attribute.id !== value.id)) {
				this.conditionals = []
			}
			this.current_attribute = value
		},
		appendCondition() {
			if(this.current_attribute){
				const data = {
					attribute: this.current_attribute,
					operator: null,
					value: null,
					string: '',
				}
				this.conditionals.push(data)
			}
		},
		removeConditional(index) {
			const position = this.conditionals.findIndex((item, i) => i === index)
			this.conditionals.splice(position, 1)
		},
		setItem(data) {
			const item = this.conditionals[data.index]
			item.operator = data.operator
			item.value = data.value
			item.string = `($X ${data.operator.value} ${data.value})`
		},
		save() {
			let error = false
			let next = ''
			let evaluate = ''
			this.conditionals.map(item => {
				if (item.value === null || item.operator === null) {
					this.$helpers.showError('Error condition with empty data')
					error = true
					return false
				}

				evaluate = `${evaluate} ${next} ${item.string}`
				next = '&&'
			})

			if (!error) {
				const data = {
					evaluate,
					reading_key: this.current_attribute.key,
					reading_key_id: this.current_attribute.id,
					reading_attribute: this.current_attribute.label,
				}
				this.$emit('save', data)
				this.conditionals = []
			}
		},
	},
}
</script>