<template>
    <div class="row">
        <div v-for="(item,key) in items" class="col-6">
            <h5>{{item.title}}</h5>
            <readings-item-chart class="shadow w-100" v-for="(readings,key) in item.readings" :key="key" :title="readings.title" :item="readings.series" ></readings-item-chart>

        </div>
    </div>
</template>

<script>
import ReadingsItemChart from "./ReadingsItemChart";

export default {
    name: "ReadingsItemComponent",
    components: {
        ReadingsItemChart
    },
    props: {
        current_installation: {required: true}
    },
    data() {
        return {
            items: [],
            installation: null
        }
    },
    created() {
        this.installation = this.current_installation ? this.current_installation : null

    },

    watch:{
        current_installation: function () {
            this.installation = this.current_installation ? this.current_installation : null

        },
        installation: function () {
            this.getItemReadings()
        },
    },
    methods: {
        getItemReadings() {
            const itemReadings = axios.get(`/api-admin/dashboard/installation/${this.installation}/readings/chart`).then(res=>{
                this.items = res

            })
        },

    }
}
</script>

<style scoped>

</style>
