<template>
	<card-template :show_header='false' padding='p-0'>

		<div v-show="$helpers.hasPermission('developer')" class='text-right m-3'>
			<button @click='newReport' class='btn btn-sm btn-primary'>
				<i class='fa fa-plus'></i>
				{{ $t('buttons.new') }}
			</button>
		</div>
		<table class='table table-hover table-condensed'>
			<thead>
			<tr>
				<th>ID</th>
				<th>{{ $tc_reports('report', 1) }}</th>
				<th>{{ $t_reports('description') }}</th>
				<th>{{ $t_reports('label_last_execution') }}</th>
				<th>{{ $t_reports('options') }}</th>
			</tr>
			</thead>
			<tbody>
			<tr v-if='reports_list.length > 0' v-for='item in reports_list' :key='item.id'>
				<td>{{ item.id }}</td>
				<td>{{ item.name }}</td>
				<td>{{ item.description }}</td>
				<td>{{ item.last_execution }}</td>
				<td>
					<button @click='generateReport(item)' class='btn btn-secondary btn-xs'>
						<i class='fa fa-chart-line'></i>
					</button>
					<button @click='editReport(item)' class='btn btn-warning btn-xs'>
						<i class='fa fa-edit'></i>
					</button>
					<button @click='deleteReport(item)' class='btn btn-danger btn-xs'>
						<i class='fa fa-trash'></i>
					</button>
				</td>
			</tr>
			<tr v-if='reports_list.length === 0'>
				<td class='text-center' colspan='5'>
					{{ $t_reports('empty_reports') }}
				</td>
			</tr>
			</tbody>
		</table>
		<ModalConfirmDeleteFn />
		<ReportFormModal />
	</card-template>
</template>
<script>
import ReportFormModal from './ReportFormModal.vue'
import {mapGetters} from 'vuex'
export default {
	name: 'ReportsList',
	props:{
		installation_id:{}
	},
	components: {
		ReportFormModal,
	},
	created() {
		this.$store.commit('reports/resetData')
		this.$store.dispatch('reports/getConfigReports')
		this.$store.dispatch('reports/getReports')
	},
	computed: {
		...mapGetters('reports', ['reports_list']),
	},

	methods: {
		newReport() {
			this.$store.commit('reports/setCurrentReport', null)
			this.$store.commit('reports/toggleFormModal')
		},
		editReport(item) {
			this.$store.commit('reports/setCurrentReport', item)
			this.$store.commit('reports/toggleFormModal')
		},
		deleteReport(item) {
			this.$bus.$emit('fireModalConfirmDeleteFn', {
				text: this.$t_reports('delete_message', {name: item.name}),
				parameters: item,
				fn: this.deleteConfirmed,
			})
		},

		deleteConfirmed(item) {
			this.$store.dispatch('reports/deleteReport', item.id)
		},

		generateReport(item) {
			const url = this.installation_id ? `/reports/${item.id}?installation_id=${this.installation_id}` : `/reports/${item.id}`
			window.open(url)
		},
	},
}
</script>