import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'
import {InstallationStore,ZoneStore,CheckpointStore,CircuitStore} from '/../Modules/Installations/Resources/assets/store'
import ReadingStore from '/../Modules/Readings/Resources/assets/store'
import {TaskStore} from "../../../Modules/Tasks/Resources/assets/store";
import {CustomerStore} from "../../../Modules/Customers/Resources/assets/store";
import SystemStore from "./system";
import ReportStore from "../../../Modules/Reports/Resources/assets/store";

Vue.use(Vuex)


export default new Vuex.Store({
	modules:{
		customers: CustomerStore,
		circuits: CircuitStore,
		checkpoints: CheckpointStore,
		installations: InstallationStore,
		readings: ReadingStore,
		reports: ReportStore,
		system: SystemStore,
		tasks: TaskStore,
		zones: ZoneStore,
	},
	plugins: [
		createPersistedState({
			storage: window.sessionStorage,
		}),
	],
})
