import GlobalTranslation from '../../../../../resources/js/Vuei18n'
import VueI18n from 'vue-i18n';

const ModuleMessages = () => {
	const global_messages = GlobalTranslation.messages;
	const ca = require('../../lang/ca.json');
	const es = require('../../lang/es.json');
	const en = require('../../lang/en.json');
	const messages = {
		ca: { ...global_messages.ca, ...ca },
		es: { ...global_messages.es, ...es },
		en: { ...global_messages.en, ...en }
	};
	return messages;
};
const translator = new VueI18n({
	locale: window.lang,
	messages: ModuleMessages()
});
Vue.prototype.$t_readings = (key, values) => translator.t(key, values);
