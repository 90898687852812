<template>
    <tr>
        <td>{{this.item.readingKey}}</td>
        <td>{{this.item.countActiveAlarms}}</td>
        <td><button class="btn btn-primary" @click="showAlarmsTable()">Ver alarmas</button></td>
    </tr>
</template>

<script>
export default {
    name: "ItemListActiveAlarms",
    props:{
        item: {default:null},
        installation: {default:null}
    },

    data() {
        return {

        }
    },
    methods: {
        showAlarmsTable(){
            const query = `search=${this.item.readingKeyId}_${this.installation}_active`
            window.open(`/alarms?${query}`,'_blank')
        }
    }
}
</script>

<style scoped>

</style>
