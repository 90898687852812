<template>
	<v-client-table
		ref='BatchTasksTable'
		:columns='columns'
		:options='options'
		:data='batch_tasks'
	>
		<template #afterFilter>
			<div  style='margin-top: -20px;' class='float-left ml-2'>
				<label class='d-block'>Instalación</label>
				<InstallationsCombo :show_label='false' @add="setInstallationFilter" style="min-width: 300px" />
			</div>

			<div  style='margin-top: -20px;' class='float-left ml-2'>
				<div>
					<label class='d-block'>Estado</label>
					<TaskStatesCombo style='min-width:150px' @selected='setStateFilter' />
				</div>
			</div>

			<div  style='margin-top: -20px;' class='float-left ml-2'>
					<DatePickerComponent label="Rango de Fechas" @range='setDateFilter'/>
			</div>

		</template>

		<div slot='beforeTable'>
			<div class='text-right'>
				<button v-if="this.$helpers.hasPermission('tasks.edit')" @click='newItem'
						class='btn btn-primary btn-sm mb-2'><i class='fa fa-plus'></i>
					{{ this.$tasks_locale.global.buttons.new }}
				</button>
			</div>
		</div>

		<div slot='options' slot-scope='{row}'>
			<button v-if="$helpers.hasPermission('tasks.edit')" @click='editItem(row)'
					class='btn btn-xs btn-warning'><i class='fa fa-pencil'></i></button>
			<button v-if="$helpers.hasPermission('tasks.delete')" @click='deleteItem(row)'
					class='btn btn-xs btn-danger'><i class='fa fa-trash'></i></button>

		</div>

		<template #date='{ row }'>
			{{ row.format_date }}
		</template>
		<template #expiration_date='{ row }'>
			{{ row.format_expiration_date }}
		</template>
	</v-client-table>
</template>

<script>
import {mapGetters} from 'vuex'
import {datatables_options} from '@/../datatables_data'
import InstallationsCombo from '@modules/Installations/Resources/assets/components/InstallationsCombo.vue'
import TaskStatesCombo from '../TaskStatesCombo.vue'
import DatePickerComponent from '@/DatePickerComponent.vue'
export default {
	name: 'BatchTasksTable',
	components:{
		InstallationsCombo,
		TaskStatesCombo,
		DatePickerComponent
	},
	computed: {
		...mapGetters('tasks', ['batch_tasks']),
		...mapGetters('system', ['app_config']),
		targetLink() {
			return this.app_config.target_blank ? '_blank' : '_self'
		},
		columnsData() {
			return [
				{
					field: 'id',
					label: '#',
					display: 'min_tabletP',
				},
				{
					field: 'name',
					label: this.$tasks_locale.global.table_columns.name,
				},

				{
					field: 'installation_name',
					label: this.$tasks_locale.global.table_columns.installation,
				},

				{
					field: 'date',
					label: this.$tasks_locale.global.table_columns.start_date,
				},
				{
					field: 'expiration_date',
					label: this.$tasks_locale.global.table_columns.expiration_date,
				},
				{
					field: 'state',
					label: this.$tasks_locale.global.table_columns.state,
				},
				{
					field: 'subtasks',
					label: 'Subtareas'
				},
				{
					field: 'options',
					label: this.$tasks_locale.global.table_columns.options,
					// display:'min_desktop'

				},

			]
		},
	},
	created() {
		this.setColumns()
		this.getData()
	},
	data() {
		return {
			columns: [],
			filter:{
				installation_id:null,
				range: null,
				state_id:null,
			},
			options: {
				...datatables_options,
				filterByColumn: false,
			},
		}
	},
	methods: {
		setColumns() {
			const headings = {}
			this.columnsData.map(item => {
				this.columns.push(item.field)
				headings[item.field] = item.label
				if (item.display) {
					this.options.columnsDisplay[item.field] = item.display
				}
			})
			this.options.headings = headings
		},
		getData() {
			this.$store.dispatch('tasks/getBatchTasksList',this.filter)
		},
		newItem() {
			window.open(`/tasks-batch/new`, this.targetLink)
		},
		editItem(item) {
			window.open(`/tasks-batch/edit/${item.id}`, this.targetLink)
		},
		deleteItem(item) {
			this.$bus.$emit('fireModalConfirmDeleteFn', {
				text: `${this.$tasks_locale.global.modals.delete_task} ${item.name} `,
				parameters: item,
				fn: this.removeItem
			})
		},
		async removeItem(item) {
			const filter = {...this.filter,id:item.id}
			await this.$store.dispatch('tasks/deleteBatchTask', filter)
			this.$helpers.toastDeleteSuccess()
		},

		setInstallationFilter(payload){
			this.filter.installation_id = payload?.id || null
			this.getData()
		},

		setStateFilter(payload){
			this.filter.state_id = payload?.id || null
			this.getData()
		},

		setDateFilter(payload){
			const range = payload.time[0] ? payload.time : null
			this.filter.range = range
			this.getData()
		}
	},

}
</script>