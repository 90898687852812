<template>
	<modal-component
		:id='id'
		:show='show_gen_report_modal'
		:hideButtons='false'
		@closeModal='cancelForm'
		@Submitted='saveForm'
	>

		<template #modalTitle>
			{{ $t_reports('new_report_gen_title',{report_name : current_report?.name}) }}
		</template>

		<div v-show="!installation_id" class='col-12 alert alert-info'>
			{{ $t_reports('new_report_gen_subtitle') }}
		</div>

		<div class='col-12 mb-2'>
			<label class='required'>{{ $t_reports('file_name') }}</label>
			<input type='text' class='form-control' v-model='name'>
		</div>
		<div class='col-12 '>
			<label class='required'>
				{{ $t_reports('report_period') }}
			</label>
			<DatePickerComponent  :current="period" @range='setRange' :show-label='false'/>
		</div>
	</modal-component>
</template>
<script>
import {mapGetters} from 'vuex'
import ModalComponent from '../../../../../resources/js/components/ModalComponent.vue'
import DatePickerComponent from '../../../../../resources/js/components/DatePickerComponent.vue'
export default {
	name: 'ReportGeneratorFormModal',
	props:{
		installation_id:{}
	},
	components: {
		ModalComponent,
		DatePickerComponent
	},
	created(){

	},
	computed: {
		...mapGetters('reports', ['current_report', 'show_gen_report_modal']),
	},
	data() {
		return {
			id: 'ReportGeneratorFormModal',
			name: null,
			period: null,
		}
	},
	methods: {
		defaultData() {
			this.name = null
			this.period = null
		},
		closeModal() {
			this.$store.commit('reports/hideReportGeneratorFormModal')
			this.defaultData()
			$(`#${this.id}`).modal('hide')
		},
		cancelForm() {
			this.$emit('cancel')
			this.closeModal()
		},
		async saveForm() {
			await this.$store.dispatch('reports/generateReport', {
				report: this.current_report,
				params:{
					name: this.name,
					period: this.period,
					installation_id: this.installation_id
				}
			})
			this.$helpers.toastSuccess()
			this.$emit('saved')
			this.closeModal()
		},
		setRange({time}){
			this.period = time[0] ? time : null
		}
	},
}
</script>