export const setCurrent = (state,payload) => {
	state.current = payload
}


export const removeCurrent = (state) => {
	state.current = null
}
export const setSelectedAcc = (state,payload)=>{
    state.selected_acc = payload
}
export const setSelectedDeposit = (state,payload)=>{
    state.selected_deposit = payload
}
export const resetCircuits = (state) => {
    state.circuits_ac = []
    state.circuits_af = []
}
export const setCircuits = (state,circuits) =>{
    state.circuits_ac = []
    state.circuits_af = []
    circuits.map(circuit => {
        if (circuit.type === 'ac') state.circuits_ac.push(circuit)
        if (circuit.type === 'af') state.circuits_af.push(circuit)
    })
}
export const setActiveAccumulatorsEdition = (state,payload)=>{
    state.active_accumulators_edition = payload
}
export const setEditedAcc = (state,payload)=>{
    state.edited_acc = payload
}
export const setActiveDepositEdition = (state,payload)=>{
    state.active_deposits_edition = payload
}
export const setEditedDeposit = (state,payload)=>{
    state.edited_deposits = payload
}
