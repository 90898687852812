<template>
    <card-template>
        <template slot="titleCard">
            <div class="row">
                <div class="col-10">
                    <h3>Análisis Legionella</h3>
                </div>

            </div>
        </template>
        <div class="row align-items-end">
            <installation-filter :clearable="false" class="col-4"  :current_installation="installation" @installationChanged="setInstallation"></installation-filter>
            <div class="ml-2">
                <button @click="exportCsv" class="btn btn-primary">Exportal Excel</button>
            </div>
        </div>
        <div class="row ">
            <div class="col-8 p-0">
                <analysis-list ref="analysisList" :items_analysis="items"></analysis-list>
            </div>
            <div class="col-4">
                <legionella-chart-donut :series="series"></legionella-chart-donut>

            </div>
        </div>
    </card-template>
</template>

<script>
import InstallationFilter from "../Dashboard/InstallationFilter";
import AnalysisList from "../Analysis/AnalysisList";
import LegionellaChartDonut from "./LegionellaChartDonut";

export default {
    name: "AnalysisLegionellaComponent",
    props: { current_installation:{default:null} },
    components: {
        LegionellaChartDonut,
        InstallationFilter,
        AnalysisList
    },
    data() {
        return {
            installation: null,
            items: [],
            series:[0,0,0]
        }
    },
    created(){
      if(this.current_installation){
          this.installation = this.current_installation
      }
    },
    watch: {
        installation() {
            this.getLegionellaReadingsInstallation()
        },


    },
    methods: {
        setInstallation(installation) {
            this.installation = installation.id
        },
        exportCsv() {
            let data =this.$refs.analysisList.$refs.analysisDatatable.$refs.analysisTable.allFilteredData;
            axios.post('/api-admin/readings/legionella/exportCsv',data).then((res)=>{
                const blob = new Blob([res], { type: 'text/csv' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = `reading_legionella_${this.formatDate(new Date()) }.csv`
                link.click()
                URL.revokeObjectURL(link.href)
            })
        },
        formatDate(date) {
            return [
                this.padTo2Digits(date.getDate()),
                this.padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('/');
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
        async getLegionellaReadingsInstallation() {
            return await axios.get(`/api-admin/dashboard/installation/${this.installation}/legionella/chart`).then(res => {
                this.items = res.data
                this.parseItemsChart()
                this.series = this.parseItemsChart()
            })
        },
        parseItemsChart() {
            let seriesAux = [0,0,0]
            this.items.map(item => {
                if(item['legionella_state']=='Positivo'){
                    seriesAux[0] += 1
                }
                if(item['legionella_state']=='Presencia'){
                    seriesAux[1] += 1
                }
                if(item['legionella_state']=='Negativa'){
                    seriesAux[2] += 1
                }
            })
            return seriesAux
        }

    }
}
</script>

<style scoped>

</style>
