<template>
	<div>
		<v-client-table ref='ReportFilesTable' :data='report_files' :columns='columns' :options='options'>
			<div class='text-right' slot='beforeTable'>
				<button @click='newReport' class='btn btn-secondary btn-sm mb-2'>
					<i class='fa fa-chart-line mr-2'></i>
					{{ $t_reports('new_report') }}
				</button>
			</div>

			<template #afterFilter>
				<div v-show="!installation" style='min-width: 300px; margin-top:-20px; float:left;'>
					<CustomersCombo
						class='ml-2'
						:label="$t_reports('label_customer')"
						@add='filterCustomer'
					/>
				</div>

				<div v-show="!installation" style='min-width: 300px; margin-top:-20px; float:left;'>
					<InstallationsCombo
						class='ml-2'
						:label="$t_reports('label_installation')"
						@add='filterInstallation'
					/>
				</div>

			</template>

			<template #period='{row}'>
				{{ row.period_string.start }} - {{ row.period_string.end }}
			</template>

			<template #created_at='{row}'>
				{{ row.created_at_string }}
			</template>

			<template #options='{row}'>
				<a :href='row.file_url' target='_blank' class='btn btn-xs btn-primary'>
					<i class='fa fa-download'></i>
				</a>
				<button @click='deleteReport(row)' class='btn btn-xs btn-danger'><i
					class='fa fa-trash'></i>
				</button>
				<button @click='regenerateReport(row)' class='btn btn-xs btn-warning'>
					<i class='fa fa-sync'></i>
				</button>
				<button @click='showReport(row)' class='btn btn-xs btn-success'>
					<i class='fa fa-eye'></i>
				</button>
			</template>
		</v-client-table>
		<ModalConfirmDeleteFn />
		<ReportGeneratorFormModal :installation_id="installation?.id || null" @saved='getData' />
	</div>
</template>
<script>
import ReportGeneratorFormModal from './ReportGeneratorFormModal.vue'
import InstallationsCombo from '../../../../Installations/Resources/assets/components/InstallationsCombo.vue'
import CustomersCombo from '../../../../Customers/Resources/assets/components/CustomerCombo.vue'
import {mapGetters} from 'vuex'

export default {
	name: 'ReportFilesTable',
	components: {
		ReportGeneratorFormModal,
		InstallationsCombo,
		CustomersCombo
	},
	props: {
		installation:{}
	},
	created() {
		this.setColumns()
	},
	computed: {
		...mapGetters('reports', ['current_report', 'report_files']),
		columnsData() {
			return [
				{
					field: 'id',
					label: '#',
					display: 'min_tabletP',
				},
				{
					field: 'name',
					label: this.$t_reports('file_name'),
				},
				{
					field: 'customer_name',
					label: this.$t_reports('label_customer'),
				},
				{
					field: 'installation_name',
					label: this.$t_reports('label_installation'),
				},
				{
					field: 'period',
					label: this.$t_reports('report_period'),
				},
				{
					field: 'created_at',
					label: this.$t_reports('report_at'),
				},
				{
					field: 'options',
					label: this.$t_reports('options'),
				},
			]

		},
	},
	data() {
		return {
			columns: [],
			options: {
				rowAttributesCallback: row => {
					return {'data-id': row.id}
				},
				filterByColumn: false,
				resizableColumns: false,
				perPage: 10,
				perPageValues: [5, 10, 50, 100],
				pagination: {chunk: 5},
				datepickerOptions: {
					showDropdowns: true,
					autoUpdateInput: true,
				},
				sortIcon: {
					base: 'fa float-right',
					is: 'fa-sort',
					up: 'fa-sort-up',
					down: 'fa-sort-down',
				},
				texts: {
					count: `${this.$t('Showing {from} to {to} of {count} records|{count} records|One record')}`,
					first: `${this.$t('First')}`,
					last: `${this.$t('Last')}`,
					filter: `${this.$t('Filter')}:`,
					filterPlaceholder: `${this.$t('Search query')}`,
					limit: `${this.$t('Records')}:`,
					page: `${this.$t('Page')}:`,
					noResults: `${this.$t('No matching records')}`,
					filterBy: `${this.$t('Filter by {column}')}`,
					loading: `${this.$t('Loading')}...`,
					defaultOption: `${this.$t('Select {column}')}`,
					columns: `${this.$t('Columns')}`,
				},
				columnsDisplay: {},
			},
		}
	},
	methods: {
		setColumns() {
			const headings = {}
			this.columnsData.map(item => {
				this.columns.push(item.field)
				headings[item.field] = item.label
				if (item.display) {
					this.options.columnsDisplay[item.field] = item.display
				}
			})
			this.options.headings = headings

		},
		getData() {
			this.$store.dispatch('reports/getReport', this.current_report.id)
		},
		newReport() {
			this.$store.commit('reports/toggleReportGeneratorFormModal')
		},
		async regenerateReport(row) {
			await this.$store.dispatch('reports/generateReport', {
				report: this.current_report,
				params: row,
			})
			this.$helpers.toastSuccess()
			this.getData()
		},
		showReport(row) {
			const params = this.$helpers.setUrlParameters({
				name: row.name,
				period: row.period,
				installation_id: row.installation_id,
			})
			const url = `/reports/${this.current_report.id}/report/${params}`
			window.open(url, '_blank')
		},
		deleteReport(file) {
			this.$bus.$emit('fireModalConfirmDeleteFn', {
				text: this.$t_reports('delete_file_message', {name: file.name}),
				parameters: file,
				fn: this.confirmDelete,
			})
		},
		async confirmDelete(file) {
			await this.$store.dispatch('reports/deleteFile', {
				id: file.id,
				report_id: this.current_report.id,
			})
			this.$helpers.toastDeleteSuccess()
			this.getData()
		},
		filterInstallation(installation) {
			this.$store.commit('reports/setFilterInstallationId', installation.id || null)
			this.getData()
		},

		filterCustomer(customer) {
			this.$store.commit('reports/setFilterCustomerId', customer.id || null)
			this.getData()
		},

	},

}
</script>