export const copyZones = (context,payload) => {
    return axios.post(`/api-admin/installations/${payload.installation_id}/zones/copy`, payload).then(response => {
        context.dispatch('getZones',payload.installation_id)
        context.commit('setSelectedZones', [])

        window.toast.success(window.trans_installations.global.zones_copied_success)
        return response
    }).catch(err=>{
        window.toast.error(window.trans_installations.global.zones_copied_error)
        return err
    });
}
export const getZones = (context,installation_id) => {
     axios.get(`/api-admin/installations/${installation_id}/zones/tree`).then(response => {
        context.commit('setZones',response)
    })
}
export const saveEditedZones = (context,installation_id) => {
    const editedZones = context.state.edited_zones
    return axios.post(`/api-admin/installations/${installation_id}/zones/save`,{editedZones}).then(response => {
        window.toast.success(window.trans_installations.global.zones_modified_success)
        context.commit('setEditedZones',[])
        context.commit('setActiveZonesEdition',false)
        return response

    }).catch(error=>{
        window.toast.error(window.trans_installations.global.zones_modified_error)
        return error

    })
}
export const saveEditedCheckpoints = (context,installation_id) => {
    const editedCheckpoints = context.state.edited_checkpoints
    return axios.post(`/api-admin/installations/${installation_id}/checkpoints/save`,{editedCheckpoints}).then(response => {
        window.toast.success(window.trans_installations.global.zones_modified_success)
        context.commit('setEditedCheckpoints',[])
        context.commit('setActiveCheckpointsEdition',false)
        return response

    }).catch(error=>{
        window.toast.error(window.trans_installations.global.zones_modified_error)
        return error

    })
}
