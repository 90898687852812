<template>
    <div class="col-12 p-0">
        <table class="table table-bordered table-striped">
            <thead>
            <tr>
                <th>Lectura</th>
                <th>Alarmas activas</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <item-list-active-alarms v-for="(item,index) in items" :key="index" :installation="current_installation" :item="item"></item-list-active-alarms>

            </tbody>
        </table>
    </div>
</template>

<script>
import ItemListActiveAlarms from "./ItemListActiveAlarms";

export default {
    name: "ListActiveAlarms",
    props: {
        current_installation: {required: true}
    },
    data() {
        return {
            installation: null,
            items:null
        }
    },
    created() {
        this.installation = this.current_installation ? this.current_installation : null

    },
    components: {
        ItemListActiveAlarms
    },
    watch: {
        current_installation: function () {
            this.installation = this.current_installation ? this.current_installation : null

        },
        installation: function () {
             this.getActiveAlarms()
        }
    },
    methods: {
        getActiveAlarms() {
            const alarms = axios.get(`/api-admin/dashboard/installation/${this.installation}/alarms/active`).then(res => {
                this.items = res
            })
        }
    }
}
</script>

<style scoped>

</style>
