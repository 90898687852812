export const setReports = (state, reports) => {
    state.reports = reports
}

export const hideFormModal = (state) => {
    state.show_form_modal = false
}

export const toggleFormModal = (state) => {
    state.show_form_modal = !state.show_form_modal
}

export const setCurrentReport = (state, report) => {
    state.current_report = report
}



export const setConfigReports = (state, reports) => {
    state.config_reports = reports
}

export const hideReportGeneratorFormModal = (state) => {
    state.show_gen_report_modal = false
}

export const toggleReportGeneratorFormModal = (state) => {
    state.show_gen_report_modal = !state.show_gen_report_modal
}

export const setFilterInstallationId = (state, installation_id) => {
    state.filter.installation_id = installation_id
}

export const setFilterCustomerId = (state, customer_id) => {
    state.filter.customer_id = customer_id
}

export const resetData = (state) => {
	state.reports = []
	state.show_form_modal = false
	state.current_report = null
	state.config_reports = []
	state.show_gen_report_modal = false
	state.filter.installation_id = null
	state.filter.customer_id = null
}