export const getCheckpoints = (context) => {
	const {current_installation_id, current_zone_id} = context.state
	const url = `/api-admin/installations/${current_installation_id}/checkpoints/zone/${current_zone_id}`
	axios.get(url).then(response => {
		const rows = response.data
		context.commit('setCheckpoints', rows)
	})
}
export const duplicate = (context, payload) => {
	const url = '/api-admin/installations/checkpoint/duplicate'
	return axios.post(url, payload).then(response => {
		return getCheckpoints(context)
	})
}

export const deleteCheckpoint = (context, payload) => {
	const {current_installation_id, current_zone_id} = context.state
	const url = `/api-admin/installations/${current_installation_id}/checkpoints/${payload.id}/zone/${current_zone_id}`
	return axios.delete(url).then(response => {
		return getCheckpoints(context)
	})
}