<template>
	<card-template>
		<div class='row' :class="installation_id==null?'d-none':''">
            <span class='w-100 mb-3'>{{ this.task_id == null ? this.$tasks_locale.global.task_form_title_create : this.$tasks_locale.global.task_form_title_edit
				}} <span
					class='h5'>{{ this.installation_name }}</span></span>
			<div class='col-12 col-lg-12'>
				<div class='row'>
					<div class='col-12 col-lg-2 mb-3'>
						<label for='nameTask' class='required'>{{ this.$tasks_locale.global.table_columns.name
							}}</label>
						<input type='text' v-model='formData.name'
							   id='nameTask' class='form-control'>
					</div>
					<div class='col-12 col-lg-2 mb-3'>
						<label for='tipoTarea' class='required'>{{ this.$tasks_locale.global.table_columns.type
							}} </label>
						<select class='form-control' v-model='formData.type' id='tipoTarea'>
							<option v-for='type in types' v-bind:value='type.id'>
								{{ typesTrans[type.name] }}
							</option>

						</select>
					</div>
					<div class='col-12 col-lg-2 mb-3'>
						<label for='fechaInicioEvento' class='required'>{{
								this.$tasks_locale.global.table_columns.start_date }} </label>
						<input type='date' @change='this.calcularGeneradas' v-model='formData.date'
							   id='fechaTarea' class='form-control'>
					</div>
					<div class='col-12 col-lg-2 mb-3'>
						<label for='fechaFinEvento' class='required'>{{
								this.$tasks_locale.global.table_columns.expiration_date }} </label>
						<input type='date' @change='this.calcularGeneradas'
							   v-model='formData.expiration_date'
							   id='fechaExpiracion' class='form-control'>
					</div>
					<div class='col-12 col-lg-2 mb-3'>
						<label for='operarioAsignado' class='text-center w-100 required'>{{
								this.$tasks_locale.global.table_columns.operators }} </label>
						<Select2 v-model='formData.operarios_asign' :options='valuesOperarios'
								 :settings="{tags:true, multiple:true, width:'100%' }" />
					</div>
					<div class='col-12 col-lg-2 mb-3'>
						<label for='eventName' class='text-center w-100 required'>¿{{
								this.$tasks_locale.global.table_columns.recurrent }} ?</label>
						<input type='checkbox' @click='switchRecurrente()' id='isRecurrente'
							   v-model='formData.is_recurrent' class='form-control-sm form-control'>
					</div>
				</div>
				<div class='row'>
					<div class='col-12 m-0 p-0'>
						<ReadingTypeCombo class='col-12 mb-3' :multiple='true' :setted='this.current_reaking_keys' @selected='setReadings' />
						<div class='col-12  mb-3'>
							<button class='btn btn-primary' type='button' @click='toggleModal(1)'>
								{{ $t('select_checkpoint') }}
							</button>
							<div class=''>
								<small id='count-cp'></small>
								<small id=''> {{ $t('items_selected') }}</small>
							</div>
						</div>
					</div>
					<div class='col-lg-6 col-12'>
						<div class='col-lg-12 col-12 bloque-evento-recurrente recurrente-off'>
							<div class='row' style='margin-left: 1px'>
								<div id='repetir' class='bloque_repetir' style='/*filter:blur(2px)*/;'>
									<div class='row rounded pb-1 mx-0  p-1' style='border:1px solid black;'>
										<div class='mx-0 mt-1 btn-toolbar  col-12'
											 role='toolbar' aria-label='Toolbar with button groups'>
											<div class='form-group'>
                                                <span
													class='w-100 '>  {{ this.$tasks_locale.global.multiple_events
													}}</span>
												<label for='repeatNumber' class='w-100 dark'>
													{{ this.$tasks_locale.global.repeat_event }}
												</label>
												<div class='btn-group' role='group' aria-label='First group'>
													<input type='number' data-input='true' placeholder=''
														   @change='calcularGeneradas'
														   class='form-control required saveable col-4 recurrency repeatNumber'
														   min='1'
														   max='99' id='repeatNumber'
														   v-model='formData.recurrent_repeat_value' value='1'>
													<button @click="activarPeriodo('#Weeks')" type='button'
															data-value='Weeks'
															class='btn btn-outline-primary btn-sm active'
															id='Weeks'>{{ this.$tasks_locale.global.buttons.week }}/s
													</button>
													<button @click="activarPeriodo('#Months')" Rtype='button'
															data-value='Months' class='btn btn-outline-primary btn-sm'
															id='Months'>{{ this.$tasks_locale.global.buttons.month }}/es
													</button>
													<!--                                               <button @click="activarPeriodo" type="button"  class="btn btn-outline-primary btn-sm"-->
													<!--                                                       id="btn-anos">Año/s-->
													<!--                                               </button>-->
													<input v-model='formData.recurrent_repeat_option'
														   id='hiddenPeriod' type='hidden'>
												</div>
											</div>
										</div>

										<div class='col-12 p-0 pl-1 mt-1'>
											<div class='btn-toolbar col-12 row mx-0 p-0'
												 role='toolbar' aria-label='Toolbar with button groups'>
												<label class='dark pr-1' for='weekDays'>
													{{ this.$tasks_locale.global.repeat_days }}
												</label>
												<input class='form-control days-of-week days1'
													   @change='calcularGeneradas'
													   id='weekDays' type='text'
													   data-bind='value: weekDays'>
											</div>
										</div>
										<div class='col-12'>
											<span class='small'>{{ this.$tasks_locale.global.generates }}</span>
											<div id='infoOrden'></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class='row'>
					<div class='col-sm-12 mb-3 '>
						<label for='descriptionTask' class=''>{{ this.$tasks_locale.global.table_columns.description
							}}</label>
						<textarea id='descriptionTask' class='form-control'
								  v-model='formData.description'></textarea>

					</div>
				</div>
				<hr>
				<div class='row' v-if='this.task_id!=null'>
					<span class='w-100 mb-3'>{{ this.$tasks_locale.global.change_state }} </span>
					<div class='col-lg-2 col-12 mb-3 '>
						<div class='w-100'>
							<label for='taskState' class=' w-100 required'>{{
									this.$tasks_locale.global.table_columns.state }} </label>
							<Select2 @change='checkChangeState' v-model='state_combo_value' :value='formData.state_id' :options='valuesStates'
									 :settings="{width:'100%' }" />
						</div>
					</div>
					<div class='col-lg-2 col-12 mb-3 '>
						<div class='w-100'>
							<label for='taskStateType' class=' w-100 required'>{{
									this.$tasks_locale.global.table_columns.state_type }} </label>
							<Select2 v-model='formData.state_type_id' :options='valuesStatesTypes'
									 :settings="{width:'100%' }" />
						</div>
					</div>
					<div class='col-sm-12 mb-3 '>
						<label for='commentsTask' class=''>{{ this.$tasks_locale.global.table_columns.comments
							}}</label>
						<textarea id='commentsTask' class='form-control'
								  v-model='formData.comments'></textarea>
					</div>
				</div>
			</div>
			<div>
				<div :key='1'>
					<checkpoint-tree-modal v-if='installation_id!=null' :installation_id='this.installation_id'
										   :checkpoints='formData.checkpoints'
										   :deposits='formData.deposits' :accumulators='formData.accumulators'
										   :show='showModal(1)'
										   @close='toggleModal(1)' />
				</div>
				<input type='hidden' name='checkpointsSelected' id='checkpointsSelected'>
				<input type='hidden' name='accumSelected' id='accumSelected'>
				<input type='hidden' name='depSelected' id='depSelected'>
			</div>

		</div>
		<div :class="installation_id!=null?'d-none':''">
			<button class='btn btn-primary' type='button' @click.stop='toggleModal(2)'>
				{{ this.$tasks_locale.global.buttons.select_installation }}
			</button>
			<div :key='2'>
				<div class='modal fade' id='selectInstallationModal' tabindex='-1' role='dialog'
					 aria-labelledby='InstallationSelectModal'
					 aria-hidden='true'>
					<div class='modal-dialog' role='document'>
						<div class='modal-content'>
							<div class='modal-header'>
								<h5 class='modal-title'>
									{{
										this.$tasks_locale.global.select_installation
									}}
								</h5>
								<button @click='cancelForm' class='close' type='button'
										data-dismiss='modal' aria-label='Close'>
									<span aria-hidden='true'>×</span>
								</button>
							</div>
							<div class='modal-body'>
								<div class='row h-100'>
									<div class='col-12 '>
										<button class='btn-installation my-2 btn btn-primary col-12'
												v-for='installation in this.installations'
												@click='setInstallation(installation.id,installation.name)'
												:key='installation.id'>{{ installation.name }}
										</button>
									</div>
								</div>
							</div>
							<div class='modal-footer'>
								<button @click='cancelForm' class='btn btn-dark' type='button' data-dismiss='modal'>
									{{ this.$tasks_locale.global.buttons.cancel }}
								</button>
							</div>
						</div>
					</div>
				</div>
				<!--                <installation-choose-modal :show="showModal(2)" @setInstallation="setInstallation"-->
				<!--                                           @close="toggleModal(2)" :task_id="this.task_id"/>-->
			</div>
		</div>

		<button v-if='installation_id!=null' @click='save' class='btn btn-primary'>
			{{ this.$tasks_locale.global.buttons.save }}
		</button>
	</card-template>
</template>

<script>
import CheckpointTreeModal from './CheckpointTreeModal.vue'
import daysWeek from '../daysWeek.json'
import Select2 from 'v-select2-component'
import ReadingTypeCombo from '@modules/Readings/Resources/assets/components/ReadingTypeCombo.vue'
export default {
	name: 'TasksForm',
	components: {
		CheckpointTreeModal,
		Select2,
		ReadingTypeCombo,
	},
	props: ['types', 'task_id', 'installation_selected', 'states', 'states_types', 'reading_keys'],
	data() {
		return {
			state_combo_value: null,
			activeModal: 0,
			zoneName: '',
			isEdit: false,
			installations: [],
			typesTrans: this.$tasks_locale.global.types_values,
			days: daysWeek,
			valuesOperarios: [],
			valuesStates: this.parseStateTask(this.states),
			valuesStatesTypes: this.parseStateTypesTask(this.states_types),
			installation_id: this.installation_selected,
			installation_name: null,
			current_reaking_keys: [],
			formData: {
				id: null,
				name: '',
				description: '',
				checkpoints: [],
				accumulators: [],
				deposits: [],
				state_id: null,
				state_type_id: null,
				comments: '',
				type: null,
				date: '',
				expiration_date: '',
				is_recurrent: null,
				recurrent_repeat_value: 1,
				recurrent_repeat_option: 'Weeks',
				recurrent_days: [],
				operarios_asign: [],
				installation_id: this.installation_selected,
				reading_keys: null,
			},
		}
	},
	created() {
		if (this.task_id) {
			this.getItem()
		} else {
			$(window).on('load', function() {
				$('#selectInstallationModal').modal('show')
			})
			this.getInstallations()
		}
		this.checkDaysSelected()
	},
	mounted() {
		this.daysOfWeekInputb()
		this.installation_selected != null ? this.getOperarios() : ''
	},
	methods: {
		cancelForm: function() {
			window.location = '/tasks/list'
		},
		showModal: function(id) {
			return this.activeModal === id
		},
		getOperarios() {
			axios.get(`/api-admin/users/${this.installation_id}`).then(response => {
				this.valuesOperarios = response
			})
		},
		getInstallations: function() {
			axios.get(`/api-admin/installations/`).then(response => {
				const rows = response.data
				this.installations = rows
			})
		},
		getItem() {
			axios.get(`/api-admin/tasks/${this.task_id}`).then(response => {
				const item = response.data
				this.state_combo_value = item.state_id
				this.formData.id = item.id
				this.formData.type = item.type_id
				this.formData.name = item.name
				this.formData.state_id = item.state_id
				this.formData.state_type_id = item.state_type_id
				this.formData.comments = item.comments
				this.formData.description = item.description
				this.formData.date = item.date
				this.formData.expiration_date = item.expiration_date
				this.formData.operarios_asign = item.users_id
				this.formData.is_recurrent = item.events_config ? true : null
				if (item.events_config) {
					this.formData.recurrent_repeat_value = item.events_config.recurrent_repeat_value
					this.formData.recurrent_repeat_option = item.events_config.recurrent_repeat_option
					this.formData.recurrent_days = item.events_config.recurrent_days != null ? item.events_config.recurrent_days.split(',') : []
					this.selectDaysEdit(this.formData.recurrent_days)
					if (!this.isEdit) {
						this.switchRecurrente()
					}
				}


				this.formData.checkpoints = item.checkpoints_id
				this.formData.accumulators = item.accumulators_id
				this.formData.deposits = item.deposits_id
				this.formData.reading_keys = item.reading_keys_id
				this.current_reaking_keys = item.reading_keys_id.map(r => ({
					reading_key_id: r,
				}))
				this.formData.installation_id = item.installation.installation_id
				this.installation_id = item.installation.installation_id
				this.installation_name = item.installation.installation.name
				$('#checkpointsSelected').val(this.formData.checkpoints)
				$('#accumSelected').val(this.formData.accumulators)
				$('#depSelected').val(this.formData.deposits)
				let countItems = this.formData.deposits.length + this.formData.accumulators.length + this.formData.checkpoints.length
				$('#count-cp').text(countItems)
				$('.info-cp-selected').removeClass('d-none')
				this.activarPeriodo('#' + this.formData.recurrent_repeat_option)
				this.getOperarios()
				this.isEdit = true

			})
		},
		setInstallation: function(id, name) {
			this.installation_id = id
			this.formData.installation_id = id
			this.installation_name = name
			this.getOperarios()
			$('.modal-backdrop').remove()
			$('body').css('overflow', 'visible')
		},
		toggleModal: function(id) {
			$('#selectInstallationModal').modal('show')

			if (this.activeModal !== 0) {
				this.activeModal = 0
				return false
			}
			this.activeModal = id
		},
		// maneja los botones para indicar periodo de frecuencia(semanas o meses)
		activarPeriodo(idButon) {
			$('#Weeks').removeClass('active')
			$('#Months').removeClass('active')
			$('#btn-anos').removeClass('active')
			this.formData.recurrent_repeat_option = $(idButon).data('value')
			$(idButon).addClass('active')
			this.calcularGeneradas()
		},
		// construye los checkbox para los dias de la semana
		daysOfWeekInputb() {
			var $field = $('#weekDays')
			var currentDays = $field.val() != undefined ? $field.val().split('') : []
			for (var i = 0; i < currentDays.length; i++) {
				var dayA = currentDays[i]
				for (var n = 0; n < this.days.length; n++) {
					var dayB = this.days[n]
					if (dayA === dayB.Value || new Date().getDay() == dayB.value || (new Date().getDay() == 0 && dayB.value)) {
						dayB.Checked = true
						currentDays[n] = this.days[n].Value
					}
				}
			}
			$field.val(currentDays.join(''))
			$field.attr('type', 'hidden')
			var options = ''
			var n = 1
			// while ($('.days' + n).length) {
			//     n = n + 1;
			// }
			var optionsContainer = 'days' + n
			$field.before('<div class="days ' + optionsContainer + '"></div>')
			for (var i = 0; i < this.days.length; i++) {
				var day = this.days[i]
				var id = 'day' + day.Name + n
				var checked = day.Checked ? 'checked="checked"' : ''
				options = options + '<div><input  disabled type="checkbox" value="' + day.Value + '" id="' + id + '" ' + checked + ' /><label for="' + id + '">' + day.Name + '</label></div>'
			}
			$('.' + optionsContainer).html(options)
		},
		// en cada cambio que se realiza sobre la configuracion de recurrencia, se actualiza el total de tareas a generar
		calcularGeneradas() {
			let frecuency = this.formData.recurrent_repeat_value
			frecuency = frecuency > 0 ? frecuency : 1
			let fechaInicioTarea = this.formData.date
			let fechaFinTarea = this.formData.expiration_date
			let weekDays = this.formData.recurrent_days
			let period = this.formData.recurrent_repeat_option

			if (fechaInicioTarea && fechaFinTarea && weekDays !== '       ' && frecuency && period) {
				fechaInicioTarea = fechaInicioTarea.split(' ')[0]
				fechaFinTarea = fechaFinTarea.split(' ')[0]
				period = frecuency + ((period === 'Weeks') ? 'Weeks' : ((period === 'Months') ? 'Months' : 'Years'))
				axios.get('/tasks/obtenerFechas/' +
					fechaInicioTarea + '/' +
					fechaFinTarea + '/' +
					period.toString() + '/' + weekDays
					, {hideLoader: true}).then((data) => {
					$('#infoOrden').text(data[4] + ' tareas recurrentes')
				})
			}
		},
		switchRecurrente() {
			let recurrenteSection = $('.bloque-evento-recurrente')
			let daysCheckbox = recurrenteSection.find('input[type="checkbox"]')
			if (recurrenteSection.hasClass('recurrente-off')) {
				recurrenteSection.removeClass('recurrente-off')
				daysCheckbox.removeAttr('disabled')
			} else {
				recurrenteSection.addClass('recurrente-off')
				daysCheckbox.attr('disabled', true)
				this.formData.recurrent_repeat_value = 1
				this.formData.recurrent_repeat_option = 'Weeks'
				this.formData.recurrent_days = []
			}
		},
		selectDaysEdit(days) {
			days.forEach((v) => {
				$('.days input[value="' + v + '"]').attr('checked', true)
			})
		},
		checkDaysSelected() {
			$('body').on('change', '.days input[type=checkbox]', (e) => {
				var value = $(e.target).val()
				if (e.target.checked) {
					this.formData.recurrent_days.push(value)
				} else {
					const i = this.formData.recurrent_days.indexOf(value)
					this.formData.recurrent_days.splice(i, 1)
				}
				this.calcularGeneradas()
			})
		},
		save() {
			this.formData.checkpoints = $('#checkpointsSelected').val().split(',')
			this.formData.accumulators = $('#accumSelected').val().split(',')
			this.formData.deposits = $('#depSelected').val().split(',')
			let formData = new FormData()
			Object.keys(this.formData).map(field => {
				formData.append(field, this.formData[field] === null ? '' : this.formData[field])
			})
			axios.post('/api-admin/tasks', formData, {headers: {'content-type': 'multipart/form-data'}})
			.then(response => {
				this.$helpers.toastSuccess()
				if (!this.task_id) {
					return window.location = `/tasks/${response.data.id}`
				}
				this.getItem()
				// setTimeout(() => {
				//     window.location = `/tasks/list`
				// }, 1000)
			})
		},
		parseStateTask(states) {
			let result = []
			states.forEach((v) => {
				result.push({id: v.id, text: this.$tasks_locale.global.states_trans[v.text]})
			})
			return result
		},
		parseStateTypesTask(statesTypes) {
			let result = []
			statesTypes.forEach((v) => {
				result.push({id: v.id, text: this.$tasks_locale.global.states_types_trans[v.text]})
			})
			return result
		},

		checkChangeState(value) {
			value = parseInt(value)
			const old_value = this.formData.state_id
			if (value === 1) {
				axios.get(`/api-admin/tasks/${this.task_id}/has-pending`).then(response => {
				}).catch(error => {
					this.state_combo_value = old_value
				})
			}
			this.formData.state_id = value
		},

		setReadings(readings) {
			this.current_reaking_keys = readings.map(r => ({
				reading_key_id: r.id,
			}))
			this.formData.reading_keys = readings.length > 0 ? readings.map(item => item.id) : []
		},
	},
}
</script>

<style lang='css'>
.bloque-evento-recurrente.recurrente-off {
	filter: blur(3px);
	display: none;
}

.weekDays input[type=checkbox]:checked + label {
	background: #7B9FCE;
	color: #f9fffe;
}

.days input[type=checkbox] {
	display: none;
}

.days input[type=checkbox] + label {
	cursor: pointer;
	border-radius: 50% !important;
	padding: calc(0.1rem) !important;
	min-width: 1.6rem !important;
	min-height: 1.6rem !important;
	width: 1.6rem !important;
	height: 1.6rem;
	margin-right: 3px;
	font-size: 0.9rem;
	text-align: center;
	background: #f9fffe;
	color: #1e1e1e;
	border: 1px solid #7B9FCE;
}

.days input[type=checkbox]:checked + label {
	background: #7B9FCE;
	color: #f9fffe;
}

.modal-backdrop {
	display: none;
}

.days > div {
	display: inline-block;
}
</style>
