<template>
    <div class="col-12 mt-2" style="height: 100vh;overflow: auto;">
        <v-client-table :ref='idTable' :data='items' :columns='columns' :options='options'>
            <template v-for="(item) in customFields">
                <div v-html :slot="item.field" v-html="item.content"> {{item.content}}</div>
            </template>
            <div slot="checkpoint_name" slot-scope="{row}" style="word-wrap: break-word">
                <span class=" w-100 overflow-auto" >
                    {{row.checkpoint_name}}
                </span>
            </div>
            <div slot='format_date'  slot-scope='{row}'>
                <span>{{parseDate(row.format_date)}}</span>
            </div>
<!--            <div class="bg" slot="legionella_analysis" slot-scope="{row}">-->
<!--                <h1 class="badge" :class=" row.legionella_state=='Positivo'?'badge-primary':(row.legionella_state=='Presencia'?'badge-info':'badge-warning') ">-->
<!--                    {{row.legionella_analysis}}-->
<!--                </h1>-->
<!--            </div>-->

        </v-client-table>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "AnalysisDatatable",
    props: {
        btnConfig: {default: false},
        columnsData: {required: true},
        idTable: {required: true},
        rowItems: {required: true},
        customFields: {required: false},
        enableCreateBtn: {default: true},
    },
    data() {
        return {
            items: [],
            columns: [],
            currentFilter: null,
            options: {
                orderBy: {
                    column: 'format_date',
                    ascending: false
                },
                dateFormat: 'YYYY-MM-DD HH:mm',
                dateColumns: ['format_date'],
                rowClassCallback: row => {
                    return row.disabled ? 'disabled-item' : null
                },

                resizableColumns: true,
                perPage: 10,
                perPageValues: [5, 10, 50, 100],
                pagination: {chunk: 5},
                datepickerOptions: {
                    showDropdowns: true,
                    autoUpdateInput: true,
                },
                sortIcon: {
                    base: 'fa float-right',
                    is: 'fa-sort',
                    up: 'fa-sort-up',
                    down: 'fa-sort-down',
                },
                texts: {
                    count: `${this.$t('Showing {from} to {to} of {count} records|{count} records|One record')}`,
                    first: `${this.$t('First')}`,
                    last: `${this.$t('Last')}`,
                    filter: `${this.$t('Filter')}:`,
                    filterPlaceholder: `${this.$t('Search query')}`,
                    limit: `${this.$t('Records')}:`,
                    page: `${this.$t('Page')}:`,
                    noResults: `${this.$t('No matching records')}`,
                    filterBy: `${this.$t('Filter by {column}')}`,
                    loading: `${this.$t('Loading')}...`,
                    defaultOption: `${this.$t('Select {column}')}`,
                    columns: `${this.$t('Columns')}`,
                },
                columnsDisplay: {},
            },
        }
    },
    methods: {
        parseDate(date){
            moment.locale('es')
            return moment(date).format('DD/MM/YYYY HH:mm')
        },
        setColumns() {
            const headings = {}
            this.columnsData.map(item => {
                this.columns.push(item.field)
                headings[item.field] = item.label
                if (item.display) {
                    this.options.columnsDisplay[item.field] = item.display
                }
            })
            this.options.headings = headings
        },
    },
    created() {
        this.setColumns()
        this.items = [...this.rowItems]
    },
    watch:{
        rowItems() {
            this.items = [...this.rowItems]

        }
    }

}
</script>

<style scoped>
.VueTables__table td{
    /*white-space:line-break !important;*/
}
</style>
