export const getCurrent = (state) => {
	return state.current
}

export const getSelectedAcc = (state)=>{
    return state.selected_acc
}

export const getInstallations = (state) => {
    return state.installations
}
export const getActiveInstallationsEdition = (state) => {
    return state.active_edited_installations
}
export const getEditedInstallations = (state) => {
    return state.edited_installations
}
