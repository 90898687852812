export const duplicateZones = (context, data) => {
    return axios.post('/api-admin/tasks/duplicate', data).then(res => {
        window.toast.success(window.trans_tasks.global.duplicate_task_success)
        return res
    }).catch(err => {
        window.toast.error(window.trans_tasks.global.duplicate_task_error)
        return err
    });

}
