<template>
	<modal-component
		:id='id'
		:show='show_form_modal'
		:hideButtons='true'>

		<template #modalTitle>
			{{ $t_reports('report_form_title') }}
		</template>

		<div class='col-12 mb-2'>
			<label class='required'>{{ $t_reports('label_name') }}</label>
			<input type='text' class='form-control' v-model='item.name'>
		</div>
		<div class='col-12 mb-2'>
			<label class='required'>{{ $t_reports('label_report_process') }}</label>
			<select class='form-control' v-model='item.report_process'>
				<option v-for='report in config_reports' :key='report' :value='report'>{{ report }}</option>
			</select>
		</div>
		<div class='col-12 mb-2'>
			<label class='required'>{{ $t_reports('label_description') }}</label>
			<textarea class='form-control' v-model='item.description'></textarea>
		</div>

		<div class='col-12 mt-2'>
			<button @click='saveForm' class='btn btn-primary btn-sm mr-2'>{{ $t('buttons.save') }}</button>
			<button @click='cancelForm' class='btn btn-danger btn-sm mr-2'>{{ $t('buttons.cancel') }}</button>
		</div>
	</modal-component>
</template>
<script>
import {mapGetters} from 'vuex'
import ModalComponent from '../../../../../resources/js/components/ModalComponent.vue'

export default {
	name: 'ReportFormModal',
	components: {
		ModalComponent,
	}, computed: {
		...mapGetters('reports', ['current_report', 'show_form_modal', 'config_reports']),
	},
	created() {
		this.setCurrentReport()
	},
	watch:{
		current_report(){
			this.setCurrentReport()
		}
	},

	data() {
		return {
			id: 'ReportFormModal',
			item: {},
		}
	},
	methods: {
		setCurrentReport() {
			if (this.current_report) {
				this.item = this.current_report
			} else {
				this.defaultItem()
			}
		},
		defaultItem() {

			this.item = {
				name: '',
				description: '',
				report_process: '',
			}
		},
		closeModal() {
			this.$store.commit('reports/hideFormModal')
			this.$store.commit('reports/setCurrentReport', null)
			this.defaultItem()
			$(`#${this.id}`).modal('hide')
		},
		cancelForm() {
			this.$emit('cancel')
			this.closeModal()
		},
		async saveForm() {
			await this.$store.dispatch('reports/saveReport', this.item)
			this.$helpers.toastSuccess()
			this.$emit('saved', this.item)
			this.closeModal()
		},
	},
}
</script>