<template>
	<div>
		<h3>{{this.$alarms_locale.global.alarms}}</h3>
		<hr>
		<card-template :show_header='false'>

		</card-template>
	</div>
</template>
<script>

export default{
	name: 'AlertsComponent',
	components:{

	}
}
</script>