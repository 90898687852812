<template>

	<div v-if='zones' class="zones_tree_section">
		<div :id='key' :key='key' v-for='(zone,key) in zones' :class="'zone_component_'+splitZoneId(key)">

			<!-- PARENT ZONE -->
			<zone-accordion  :refresh_fn='refresh_fn' :edit_fn='edit_fn' v-if='!zone.item.wet_zone' :zone='zone'>
				<zone-component  :refresh_fn='refresh_fn' :edit_fn='edit_fn' :installation_id='installation_id' :zones='zone.children' />
			</zone-accordion>

			<!-- WET ZONE -->
			<ul v-else class='list-group'>
				<zone-wet  :refresh_fn='refresh_fn' :edit_fn='edit_fn' :installation_id='installation_id' :zone='zone' />
			</ul>

		</div>
	</div>
</template>
<script>
import ZoneAccordion from './ZoneAccordion'
import ZoneWet from './ZoneWet'

export default {
	name: 'ZoneComponent',
	created(){

	},
    methods:{
        splitZoneId(key){
            return key.split('_')[1]
        }
    },
	props: {
		zones: {required: true},
		installation_id: {required: true},
		edit_fn: {},
		refresh_fn: {required: true, type: Function},
	},
	components: {
		ZoneAccordion,
		ZoneWet,
	},
}
</script>
