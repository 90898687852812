export const setCurrent = (state,payload) => {
	state.current = payload
}


export const removeCurrent = (state) => {
	state.current = null
}
export const setSelectedAcc = (state,payload)=>{
    state.selected_acc = payload
}

export const setInstallations = (state,payload) => {
    state.installations = payload
}
export const setSelectedInstallations = (state,payload) => {
    state.installations = payload
}
export const setActiveInstallationsEdition = (state,payload) => {
    return state.active_edited_installations = payload
}
export const setEditedInstallations = (state,payload) => {
    return state.edited_installations = payload
}
