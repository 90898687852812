<template>
    <div>
        Ejemplo de componente en Attachments
        <p>{{ this.$t('buttons.remove') }}</p>
        <p>{{ this.$attachments_locale.global.attachments }}</p>
    </div>
</template>
<script>

export default{
    name:"ExampleComponent",
    props:{},
    components:{},
    created(){},
    data(){
        return {

        }
    },
    methods:{}
}
</script>
