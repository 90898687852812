import Swal from 'sweetalert2'
import 'js-loading-overlay'
import locale from './locale'

const messages = locale.getMessages()

const helpers = {
	t(key) {
		const sessionLocale = window.lang
		let translation = messages[sessionLocale]
		const keys = key.split('.')
		keys.map((k) => {
			translation = translation[k]
		})
		return translation
	},
	showLoading() {
		JsLoadingOverlay.show({spinnerIcon: 'ball-clip-rotate-multiple'})
	},
	hideLoading() {
		JsLoadingOverlay.hide()
	},
	showError(message) {
		Swal.fire({
			title: helpers.t('modals.error.title'),
			text: message,
			icon: 'error',
			showCancelButton: false,
			// confirmButtonColor: i18n.t('modals.error.title'),
			confirmButtonText: helpers.t('buttons.success'),
		})
	},

	showErrorValidation(errors) {
		let message = ''
		Object.keys(errors).map((key) => {
			message += `<p>${errors[key]}</p>`
		})
		Swal.fire({
			title: helpers.t('modals.error.title'),
			html: `<div class='col-xs-12 col-sm-8 offset-sm-2 text-left'>${message}</div>`,
			icon: 'error',
			showCancelButton: false,
			// confirmButtonColor: i18n.t('modals.error.title'),
			confirmButtonText: helpers.t('buttons.success'),
		})
	},

	toastSuccess() {
		toast.success(helpers.t('toast.create_success'))
	},

	sanitizeCode(str) {
		str = str.replace(/[\u0300-\u036f]/g, '')
		str = str.replace(' ', '_')
		str = str.replace(/[^a-z0-9áéàèòíóúñü \.,_-]/gim, '')
		return str.trim()
	},

	setUrlParameters(object) {
		const keys = Object.keys(object)
		let glue = ''
		let string = '?'
		keys.map(key => {
			string += `${glue}${key}=${object[key]}`
			glue = '&'
		})
		return string
	},
    hasPermission(permission) {
        return window.userAdmin || window.userPermissions.includes(permission)
    },
	orderArrayObjects(property) {
		var sortOrder = 1
		if (property[0] === '-') {
			sortOrder = -1
			property = property.substr(1)
		}
		return function(a, b) {
			/* next line works with strings and numbers,
			 * and you may want to customize it to your needs
			 */
			var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
			return result * sortOrder
		}
	},
}

window.helpers = helpers

export default helpers
