<template>
    <div>
        <a :href="`#item-${zone.item.code}`" :class="!isOpen ? 'd-block card-header py-3 collapsed' : 'd-block card-header py-3'" data-toggle="collapse"
           role="button" :aria-expanded="isOpen ? 'true' : 'false' " :aria-controls="`#item-${zone.item.code}`">
            <zone-item  :refresh_fn="refresh_fn" :edit_fn="edit_fn" :zone="zone" :installation_id="zone.item.installation_id" />
        </a>

        <div :class="isOpen ? 'p-2 bg-gray-600 collapse show' : 'p-2 bg-gray-600 collapse' "  :id="`item-${zone.item.code}`">
            <div class="card shadow mb-4">
                <div class="card-body p-0">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ZoneItem from './ZoneItem'
export default {
    name: 'ZoneAccordion',
    props: {
        zone: {required: true},
        edit_fn: {},
        refresh_fn: {required:true, type:Function},
    },
	created(){
	 const current_id = parseInt(window.location.href.split('#').pop())
		this.openId = current_id

	},

    components: {
        ZoneItem
    },
    data() {
        return {
			openId: null
		}
    },
	computed:{
		isOpen(){
			return this.openId === this.zone.item.id
		}
	},
    methods: {
        deleteItem(item) {
        },
        editItem(item) {
        },

    }
}
</script>
