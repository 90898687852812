<template>
	<modal-component
		:show='showModal'
		:id='id'
		modalSize='sm'
		:hideButtons='true'
		@closeModal='closeModal'
	>
		<template #modalTitle>
			{{ $t_readings('reading_modal.title') }}
		</template>
		<template #modalFooter>
			<button class='btn btn-primary btn-sm' @click='closeModal'>
				{{ $t('buttons.close') }}
			</button>
		</template>
		<div v-if='current_reading' class='w-100 text-left'>
			<div class='w-100 text-left'>
				<small>{{ $t_readings('table_columns.reading') }}: #{{ current_reading.id }}</small>
			</div>
			<div class='w-100 text-left'>
				<small>{{ $t_readings('table_columns.reading_date') }}: {{ reading_date }}</small>
			</div>
			<div class='w-100 text-left'>
				<small>{{ $t_readings('table_columns.operator') }}: {{ current_reading.user.name }}</small>
			</div>

			<table class='mt-1 table-reading-detail table table-bordered table-striped'>
				<tbody class='text-left'>
				<DetailItem v-for='item in current_reading.reading_key'
							:key='item'
							:reading_key='item'
							:current_reading='current_reading' />

				<!--			<tr class="${!data.reading_key.includes('cleaning_review')?'d-none':''}">-->
				<!--				<td class="{{current_reading.hasAlarm&&data.alarms.includes('cleaning_review')?'text-danger':''}">{{ $t_readings('table_columns.cleaning_review')</td>-->
				<!--				<td class="{{current_reading.hasAlarm&&data.alarms.includes('cleaning_review')?'text-danger':''}">-->
				<!--					{{ current_reading.cleaning_review == 1 ? 'Correcto' : 'Incorrecto'}</td>-->
				<!--			</tr>-->

				<!--			<tr class="${!data.reading_key.includes('legionella_analysis')?'d-none':''}">-->
				<!--				<td class="{{current_reading.hasAlarm&&data.alarms.includes('legionella_analysis')?'text-danger':''}">{{ $t_readings('table_columns.legionella_analysis')</td>-->
				<!--				<td class="{{current_reading.hasAlarm&&data.alarms.includes('legionella_analysis')?'text-danger':''}">-->
				<!--				<td class="{{current_reading.hasAlarm&&data.alarms.includes('legionella_anasulysis')?'text-danger':''}">-->
				<!--					{{ current_reading.legionella_analysis == 0 ? 'Correcto' : data.legionella_analysis <= 100 ? 'Aceptable' : 'Incorrecto'} ({{ current_reading.legionella_analysis})</td>-->
				<!--			</tr>-->

				<tr>
					<td>{{ $t_readings('table_columns.comments')}}</td>
					<td><small> {{ current_reading.comments }} </small></td>
				</tr>
				</tbody>
			</table>
		</div>
	</modal-component>
</template>
<script>
import ModalComponent from '@/ModalComponent.vue'
import DetailItem from './DetailItem.vue'
import dayjs from 'dayjs'

export default {
	name: 'ReadingDetailModal',
	props: {
		id: {required: true},
		current_reading: {required: true},
		showModal: {required: true},
	},
	components: {
		ModalComponent,
		DetailItem,
	},
	computed: {
		reading_date() {
			return dayjs(this.current_reading.reading_date).format('DD/MM/YYYY HH:mm')
		},
	},
	methods: {
		closeModal() {
			this.$emit('closeModal')
		},
	},
}
</script>