export function getContent(installationsIds, customers, transLocale) {
    console.log(transLocale)

    const copyDependencesOptions = [
        'circuits',
        'accumulators',
        'deposits',
        'zones',
        'checkpoints',
        'consigns',
        'tasks'
    ];

    return `
    <div class="row justify-content-center m-0">
        <div class="col-12">
            <label>${transLocale.duplicate_copies_number}</label>
            <input  id="copies-number" value="1" type="number"  class="form-control swal2-input w-75 mt-0 mb-3" placeholder="${transLocale.duplicate_copies_number}">
            <label>${transLocale.select_customer_id}</label>
            <select  id="customer-id" class="form-control form-select swal2-select w-75 mt-0 mb-5"><option selected disabled>Selecciona un cliente</option>
              ${Object.entries(customers)
        .map(([index, customer]) => `<option  value="${customer.id}">${customer.name}</option>`)
        .join('')}
            </select>
            <label class="d-none">${transLocale.select_duplicate_dependencies}</label>
            <table class="table  d-none">
                <thead>
                <tr>
                <th>${transLocale.dependences}</th>
                <th>${transLocale.copy}</th>
            </th>
            </thead>
            <tbody>
                 ${Object.entries(copyDependencesOptions)
        .map(([index, dependence]) => ` <tr>
                <td>${transLocale[dependence]}</td>
                <td><input type="checkbox" name="" checked value="${dependence}" class="checkbox-dependence"></input></td>
            </tr>`)
        .join('')}
            </tbody>
            </table>

        </div>
    </div>
`;
}
