<template>
        <card-template>
            <template slot="titleCard">
                <div class="row">
                    <div class="col-10">
                        <h3>Dashboard general</h3>
                    </div>
                   <div class="col-2">
                       <button-analysis :installation="installation" class="text-right"></button-analysis>
                   </div>
                </div>
            </template>

            <div class="row align-items-center">
                <div class="col-12">
                    <h4>Alarmas activas</h4>
                    <installation-filter :clearable="false" class="mb-3" @installationChanged="setInstallation"></installation-filter>
                    <list-active-alarms :current_installation="installation"></list-active-alarms>
                </div>
                <div class="col-12">
                    <h4>Gráficos de progresión</h4>
                    <readings-item-component class="mb-3" :current_installation="installation"></readings-item-component>
                </div>
            </div>
        </card-template>
</template>

<script>
import InstallationFilter from "./InstallationFilter";
import ListActiveAlarms from "./ListActiveAlarms";
import ButtonAnalysis from "./ButtonAnalysis";
import ReadingsItemComponent from "./ReadingsItemComponent";
export default {
    name: "DashboardComponent",
    components:{
        InstallationFilter,
        ButtonAnalysis,
        ListActiveAlarms,
        ReadingsItemComponent
    },
    data() {
      return {
          installation:null
      }
    },
    watch:{
        installation(){

        }
    },
    methods: {
        setInstallation(installation){
            this.installation = installation.id
        },

    }
}
</script>

<style scoped>

</style>
