<template>
	<div>
		<v-client-table
			:ref='idTable'
			:data='items'
			:columns='columns'
			:options='options'>
			<span slot='purge' slot-scope='props'
			>{{ props.row.purge == 1 ? 'Purgado' : '' }}
			</span>

			<div slot='reading_value' slot-scope='{row}'>
				<span>{{ getAlarmReadingValue(alarmKey, row[alarmKey]) }}</span>
			</div>
			<div slot='reading_date' slot-scope='{row}'>
				<span>{{ parseDate(row.reading_date_aux) }}</span>
			</div>
			<div slot='afterFilterWrapper'>
				<div class='d-flex align-items-center mb-3'>
					<div v-if="this.customFilters.includes('checkpoints')" class='mx-2 mb-2'>

						<label class='d-block'>
							{{
								this.$readings_locale.global.table_columns
									.checkpoint
							}}:
						</label>
						<reading-filter-checkpoint
							@setFilter='setFilter'
							:current='currentFilter'
							class='mb-2' />

					</div>

					<div v-if="this.customFilters.includes('date_range')" class='mx-2 mb-2'>
						<date-picker-component
							@range='setFilterRange'
							:current='filter_date_range || []'
							:label='$readings_locale.global.table_columns.reading_date'
						/>
					</div>

					<div class='mx-2 mb-2 pt-4'>
						<button
							class='btn btn-excel'
							style='background-color: #1d6f42; color: white'
							@click='exportCsv()'>
							<i class='fa fa-file-excel mr-1'></i>Exportar CSV
						</button>
					</div>
				</div>
			</div>

			<template #options='{row}'>
				<button
					@click='showDetail(row)'
					class='btn btn-xs btn-light px-2'>
					<i
						:class="
							(row.hasAlarm
								? 'text-danger fa-warning'
								: 'text-primary fa-info-circle') + ' fa mr-1'
						"></i
					>Detalle
				</button>
				<button
					v-if="$helpers.hasPermission('readings.delete')"
					@click='deleteItem(row)'
					class='ml-2 btn btn-xs btn-danger px-2'>
					<i class='fa fa-trash'></i>
				</button>
			</template>
		</v-client-table>
		<ReadingDetailModal
			:id='`ReadingDetailModal${type}`'
			:show-modal='show_detail_modal'
			:current_reading='current_reading'
			@closeModal='closeModal' />
	</div>
</template>
<script>
import ReadingFilter from './ReadingFilterCheckpoint'
import ReadingFilterCheckpoint from './ReadingFilterCheckpoint'
import 'animate.css'
import moment from 'moment'
import ReadingDetailModal from './detail_modal/ReadingDetailModal.vue'
import {datatables_options} from '../../../../../resources/js/datatables_data.js'
import DatePickerComponent from '@/DatePickerComponent.vue'
import {mapGetters} from 'vuex'

export default {
	name: 'ReadingTable',
	props: {
		user_admin: {default: false},
		btnConfig: {default: false},
		customFilters: {required: false},
		columnsData: {required: true},
		idTable: {required: true},
		rowItems: {required: true},
		enableCreateBtn: {default: true},
		type: {required: true},
		readingsId: {required: false},
		alarmData: {required: false},
		// optionsTable: {required: true},
		// zone_id: {required: true},
	},
	computed: {
		...mapGetters('readings', ['filter_date_range']),
	},
	components: {
		ReadingFilterCheckpoint,
		ReadingFilter,
		ReadingDetailModal,
		DatePickerComponent,
	},
	created() {
		this.setColumns()
		this.items = [...this.rowItems]
	},
	watch: {
		rowItems: function(newItems) {
			this.items = [...newItems]
		},
	},
	data() {
		return {
			show_detail_modal: false,
			current_reading: null,
			items: [],
			columns: [],
			booleanColumns: this.$readings_locale.config.boolean_keys,

			currentFilter: [],
			alarmKey:
				this.alarmData != null ? this.alarmData.reading_key.key : null,
			options: {
				...datatables_options,
				filterable: ['id','installation_name','item_name','zone_path'],
			},
		}
	},
	methods: {
		parseDate(date) {
			moment.locale('es')
			return moment(date).format('DD/MM/YYYY HH:mm')
		},
		exportCsv() {
			let data = this.$refs.readingsTable.allFilteredData
			axios
			.post('/api-admin/readings/exportCsv/' + this.type, data)
			.then((res) => {
				const blob = new Blob([res], {type: 'text/csv'})
				const link = document.createElement('a')
				link.href = URL.createObjectURL(blob)
				link.download = `lecturas_${this.type}_${this.formatDate(
					new Date(),
				)}.csv`
				link.click()
				URL.revokeObjectURL(link.href)
			})
		},
		formatDate(date) {
			return [
				this.padTo2Digits(date.getDate()),
				this.padTo2Digits(date.getMonth() + 1),
				date.getFullYear(),
			].join('/')
		},
		padTo2Digits(num) {
			return num.toString().padStart(2, '0')
		},
		setColumns() {
			const headings = {}
			this.columnsData.map((item) => {
				this.columns.push(item.field)
				headings[item.field] = item.label
				if (item.display) {
					this.options.columnsDisplay[item.field] = item.display
				}
			})
			this.options.headings = headings
		},
		refreshData(items) {
			this.items = items
		},

		editItem(item) {
			this.$emit('edit', item)
		},
		deleteItem(item) {
			this.$emit('delete', item)
		},
		newItem() {
			this.$emit('new')
		},
		config(item) {
			this.$emit('config', item)
		},

		setFilter(filter) {
			let payload = null
			if (filter) {
				const filterKey = Object.keys(filter)[0]
				payload = filter[filterKey]
			}

			this.$store.commit('readings/setFilterTypeCheckpoint', payload)
			this.$store.dispatch('readings/getReadings')
		},

		setFilterRange(range) {
			this.$store.commit('readings/setFilterDateRange', range.time)
			this.$store.dispatch('readings/getReadings')
		},
		filterResults() {
			let filtersKey = Object.keys(this.currentFilter)
			let queryParams = '?'
			filtersKey.forEach((value, key) => {
				queryParams += `${value}=${this.currentFilter[value]}&`
			})
			queryParams += `readings_id=${JSON.stringify(this.readingsId)}`
			//const filter = this.currentFilter ? {checkpoint_filter:this.currentFilter.key} : {}
			// queryParams = this.$helpers.setUrlParameters(filter)
			axios
			.get(
				`/api-admin/readings/getReadings/${this.type}/${queryParams}`,
			)
			.then((response) => {
				const rows = response.data
				this.items = rows
				this.refreshData(rows)
			})
		},
		restore(item) {
			let queryParams = `?readings_id=${JSON.stringify(this.readingsId)}`
			axios
			.get(
				`/api-admin/readings/getReadings/${this.type}/restore${queryParams}`,
			)
			.then((response) => {
				this.filterResults()
			})
		},
		showDetail(data) {
			this.current_reading = data
			this.show_detail_modal = !this.show_detail_modal
		},
		getAlarmReadingValue(key, value) {
			if (this.booleanColumns.includes(key)) {
				return value == 1 ? 'Si' : 'No'
			}
			return value
		},
		closeModal() {
			this.show_detail_modal = false
			this.current_reading = null
		},
	},
}
</script>

<style lang='scss'>
tr.VueTables__row.disabled-item {
	background-color: #ffe5e5 !important;
}

.VueTables {
	label {
		margin-right: 10px;
	}
}

.VueTables__wrapper {
	max-height: 500px;
	overflow-y: scroll;
}

.VueTables__search-field {
	display: flex;
}

.VueTables__limit-field {
	display: flex;
}

.VueTables__columns-dropdown-wrapper {
	margin-right: 10px;
}

.VueTables__row {
	td {
		padding: 3px 5px;
		vertical-align: middle;
	}
}

.VueTables__columns-dropdown {
	input {
		margin-right: 3px;
	}
}

.VueTables__limit-field {
	select {
		margin: 5% 0;
	}
}

.table-reading-detail {
	font-size: 13px;

	tr {
		text-align: left;
	}

	td {
		text-align: center;
		padding: 2px 0;
		color: black;
		vertical-align: middle;
	}
}

.modal-detail-reading {
	color: black;
	font-size: 13px;
}
</style>
