<template>
	<card-template :show_header='false'>
		<div class='d-flex justify-content-between'>
			<h2>
				{{report.name}}
				<span v-if='installation'>{{installation.name}}</span>

			</h2>
			<small class=''>{{$t_reports('label_last_execution')}}: <b>{{report.last_execution}}</b></small>
		</div>
		<div v-show='report.description' class='description-block'>
			<b>{{$t_reports('label_description')}}</b>: {{report.description}}
		</div>

		<hr>

		<div class='pt-2'>
			<ReportFilesTable :installation='installation'  />
		</div>


	</card-template>
</template>
<script>
import ReportFilesTable from './ReportFilesTable.vue'
import {mapGetters} from 'vuex'
export default {
	name: 'Report',
	props:{
		'report_id':{required:true},
		installation:{}
	},
	components: {
		ReportFilesTable,
	},
	computed: {
		...mapGetters('reports', ['current_report']),
	},
	watch:{
		current_report(){
			this.report = this.current_report
		}
	},
	created() {
		this.getReport()
	},
	data() {
		return {
			report: {}
		}
	},
	methods: {
		getReport() {
				this.$store.commit('reports/setFilterInstallationId', this.installation?.id || null)
			this.$store.dispatch('reports/getReport', this.report_id)
		},
	},
}
</script>
<style lang="scss" scoped>
.description-block{
	padding: 3px;
	border: 1px solid #1a202c54;
	border-radius: 5px;
}
</style>