export default {
	current: null,
    selected_acc:[],
	selected_deposit:[],
    circuits_ac:[],
    circuits_af:[],
	active_accumulators_edition:false,
	active_deposits_edition:false,
	edited_acc:[],
	edited_deposits:[]
}
