<template>
	<div>
		<div class=' table-responsive'>
			<div class='mb-3 text-right'>
        <a v-if="!getActiveDepositsEdition" type="button" @click="editDeposits($event,installation_id)"
           class="btn btn-warning btn-sm"><i
            class="fa fa-pencil"></i>
          {{ this.$installations_locale.global.active_deposit_edition }}
        </a>
        <a v-if="getActiveDepositsEdition" type="button" @click="saveDepositsEdited($event,installation_id)"
           class="btn btn-primary btn-sm"><i
            class="fa fa-save"></i>
          {{ this.$installations_locale.global.buttons.save }}
        </a>
        <a v-if="getActiveDepositsEdition" type="button" @click="editDeposits($event,installation_id)"
           class="btn btn-warning btn-sm"><i
            class="fa fa-times"></i>
          {{ this.$installations_locale.global.buttons.cancel }}
        </a>
                <button @click="copyDeposit()" class="btn btn-sm btn-info"><i class="fa fa-copy"></i>
                    {{ this.$installations_locale.global.copy_selected_deposit }}
                </button>
				<button @click="newItem" class='btn btn-sm btn-success'><i class='fa fa-plus'></i>
					{{ this.$installations_locale.global.new_deposit }}
				</button>
			</div>
			<table class='table'>
				<thead>
				<tr>
                    <th><input type="checkbox" name="select-all" class="select-all"></th>

                    <th :key='column.field' v-for='column in columns'>{{ column.label }}</th>
				</tr>
				</thead>
				<tbody>
				<tr :key='item.id' v-for='item in deposits'>
                    <td><input type="checkbox" @input="setSelectedDeposit($event,item.id)" :name="'select-'+item.id" class="check-deposit"></td>

                    <td>{{ item.id }}</td>
          <td v-if="getActiveDepositsEdition" class="input-edit-acc" style="display: inline-block">
            <input type="text" class="form-control"  @input="setEditedDeposit(item.id,item.code)" style="display: inline-block;width: 400px" v-model="item.code" >
          </td>
          <td v-else="getActiveDepositsEdition">{{ item.code }}</td>
					<td>
						<button @click='editItem(item)' class='btn btn-xs btn-warning'><i class='fa fa-pencil'></i></button>
						<button @click='deleteItem(item)' class='btn btn-xs btn-danger'><i class='fa fa-trash'></i></button>
                        <button @click="copyDeposit(item.id)" class="btn btn-xs btn-info"><i class="fa fa-copy"></i>
                        </button>
					</td>
				</tr>
				</tbody>
			</table>

		</div>
	</div>

</template>
<script>
import {mapGetters} from "vuex";
import {getActiveDepositsEdition} from "../../../../store/circuits/getters";

export default {
	name: 'Deposits',
	props: {
		deposits: {type: Array},
		circuit_id: {required: true},
		installation_id: {required: true},
		refresh_fn: {}
	},
	data() {
		return {
			idTable: 'DepositTable',
			columns: [
				{
					field: 'id',
					label: '#',
					display: 'min_tabletP',
				},
				{
					field: 'code',
					label: this.$installations_locale.global.code,
				},
				{
					field: 'options',
					label: this.$installations_locale.global.options,
				},
			],
		}
	},
  computed:{
    ...mapGetters('circuits',['getActiveDepositsEdition','getEditedDeposits'])
  },
  created() {
    this.$store.commit('circuits/setSelectedDeposit', [])
    this.$store.commit('circuits/setActiveDepositEdition',false)

  },
	methods: {
		editItem(item) {
			// window.location = `/installations/${this.installation_id}/config/circuits/${this.circuit_id}/deposits/${item.id}`
            window.open(`/installations/${this.installation_id}/config/circuits/${this.circuit_id}/deposits/${item.id}`,'_blank')

        },
		deleteItem(item) {
			this.$bus.$emit('fireModalConfirmDeleteFn', {
				text: `${this.$installations_locale.global.modals.delete_deposit} ${item.code} `,
				parameters: item,
				fn: this.removeDeposit
			})
		},
		removeDeposit(item) {
			const url = `/api-admin/installations/${this.installation_id}/circuits/${item.circuit_id}/deposits/${item.id}`
			axios.delete(url).then(response => {
				this.$toast.success(this.$t('toast.delete_success'))
				this.refresh_fn(response.data)
			})
		},
		newItem() {
			// window.location = `/installations/${this.installation_id}/config/circuits/${this.circuit_id}/deposits`
            window.open(`/installations/${this.installation_id}/config/circuits/${this.circuit_id}/deposits`,'_blank')
		},

    copyDeposit(depositId = null){
      let depositIds = []
      if (depositId) {
        depositIds =  [depositId]
      }else{
        depositIds = this.$store.getters['circuits/getSelectedDeposit']
      }
      this.$swal.fire({
        title: this.$installations_locale.global.duplicate_deposit,
        text: this.$installations_locale.global.duplicate_copies_amount,
        input: 'number',
        inputValue:1,
        inputAttributes: {
          autocapitalize: 'off',
          placeholder: this.$installations_locale.global.duplicate_copies_amount_placeholder
        },
        showCancelButton: true,
        confirmButtonText: this.$installations_locale.global.buttons.success,
        cancelButtonText: this.$installations_locale.global.buttons.cancel,
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading()
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$store.dispatch('circuits/copyDeposits', {deposit_ids: depositIds, copies_number: result.value})
          this.$store.dispatch('circuits/getCircuits',this.installation_id)
          $('.check-deposit:checked').prop('checked',false)
        }
      })
    },
    setSelectedDeposit(e,depositId){

      const selected = this.$store.getters['circuits/getSelectedDeposit']

      if($(e.target).is(':checked')){
        selected.push(depositId)
      }else{
        const index = selected.findIndex(item=> item == depositId)
        selected.splice(index,1)
      }
      this.$store.commit('circuits/setSelectedDeposit',selected)
    },
    editDeposits() {
      this.$store.commit('circuits/setActiveDepositEdition', !this.getActiveDepositsEdition)

    },
    saveDepositsEdited() {
      this.$store.dispatch('circuits/saveEditedDeposits', this.installation_id)
    },
    setEditedDeposit(depositId,depositCode){
      let editedDeposit = this.getEditedDeposits
      let checkExist = null
      if(editedDeposit.length > 0){
        checkExist = editedDeposit.find(item => item.id == depositId)
      }
      if(checkExist){
        checkExist.code = depositCode
      }else{
        editedDeposit.push({id:depositId,code:depositCode})
      }
    }
	},
}
</script>
