export const getCurrent = (state) => {
	return state.current
}

export const getSelectedAcc = (state)=>{
    return state.selected_acc
}
export const getSelectedDeposit = (state)=>{
	return state.selected_deposit
}
export const getCircuitsAC = (state)=>{
    return state.circuits_ac
}
export const getCircuitsAF = (state)=>{
    return state.circuits_af
}
export const getActiveAccumulatorsEdition = (state)=>{
	return state.active_accumulators_edition
}
export const getEditedAcc = (state)=>{
	return state.edited_acc
}
export const getActiveDepositsEdition = (state)=>{
	return state.active_deposits_edition
}
export const getEditedDeposits = (state)=>{
	return state.edited_deposits
}
