<template>
		<div :id="'CircuitsList_'+circuit_type">
				<div class="text-right alert alert-dark mb-3">
						<button @click="newItem" class="btn btn-success btn-sm"><i class="fa fa-plus"></i>
								{{ this.$installations_locale.global.new_circuit }}
						</button>
				</div>
				<div :id="'CircuitAccordion_'+circuit_type">
						<circuit-ac
								v-if="circuit_type === 'ac'"
								:key="i" v-for="(item,i) in getCircuitsAC"
								class="card"
								:installation_id="installation_id"
								:item="item"
								@edit="editItem"
								:refresh_fn="refreshElements"
						></circuit-ac>

						<circuit-af
								v-if="circuit_type === 'af'"
								:key="i" v-for="(item,i) in getCircuitsAF"
								class="card"
								:installation_id="installation_id"
								:item="item"
								@edit="editItem"
								:refresh_fn="refreshElements"
						></circuit-af>

				</div>
				<modal-confirm-delete-fn/>
				<circuit-modal :circuit_type="circuit_type" :current="current" :installation_id="installation_id" :id="modalId" @saved="refreshElements"></circuit-modal>
		</div>
</template>
<script>

import CircuitModal from './CircuitModal.vue'
import CircuitAc from './CircuitAC.vue'
import CircuitAf from './CircuitAF.vue'
import {mapGetters} from "vuex";

export default {
		name: 'CircuitsList',
		props: {
				'installation_id': {required: true},
				'circuit_type': {required: true, type: String}, // ac | ac
		},
		components: {
				CircuitModal,
				CircuitAc,
				CircuitAf,
		},
		created() {
				this.getItems()
		},
    computed:{
            ...mapGetters('circuits',['getCircuitsAC','getCircuitsAF'])
    },
		data() {
				return {
						current: null,
						modalId: 'CircuitModal_' + this.circuit_type,
						idTable: 'CircuitsTable_' + this.circuit_type,
						items_ac: [],
						items_af: [],
				}
		},
		methods: {
				getItems() {
                    this.$store.dispatch('circuits/getCircuits',this.installation_id);
				},
				setCircuits(circuits){
						this.items_ac = this.getCircuitsAC
						this.items_af = this.getCircuitsAF
				},
				newItem() {
						this.current = null
						$(`#${this.modalId}`).modal('show')
				},

				editItem(item) {
						this.current = item
						$(`#${this.modalId}`).modal('show')
				},

				refreshElements(elements) {
						this.setCircuits(elements)
				},

		}
}
</script>
