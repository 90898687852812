
export const copyInstallations = (context,payload) => {
    return axios.post(`/api-admin/installations/copy`, payload).then(response => {
        context.dispatch('getInstallations')
        context.commit('setSelectedInstallations', [])
        window.toast.success(window.trans_installations.global.installations_copied_success)
        return response
    }).catch(err=>{
        window.toast.error(window.trans_installations.global.installations_copied_error)
        return err
    });
}

export const getInstallations = (context) =>{
    return axios.get('/api-admin/installations').then(response => {
        context.commit('setInstallations',response.data)
    })
}
export const saveEditedInstallations = (context) => {
    const editedInstallations = context.state.edited_installations
    return axios.post(`/api-admin/installations/save`,{editedInstallations}).then(response => {
        window.toast.success(window.trans_installations.global.installations_modified_success)
        context.commit('setEditedInstallations',[])
        context.commit('setActiveInstallationsEdition',false)
        return response

    }).catch(error=>{
        window.toast.error(window.trans_installations.global.installations_modified_error)
        return error

    })
}
