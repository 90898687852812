<template>
    <div>
        <v-client-table :ref="idTable" :data="items" :columns="columns" :options="options" :search="search">
            <div v-show="enableCreateBtn" class="text-right" slot="beforeTable">
                <button @click="newItem" class="btn btn-primary btn-sm mb-2"><i class="fa fa-plus"></i>
                    {{ this.$t('buttons.new') }}
                </button>
            </div>
            <div slot='afterFilterWrapper'>
                <a v-if="!getActiveInstallationsEdition&&enableGeneralEdition"  type="button" @click="editItem($event,installation_id)"
                   class="btn btn-warning btn-sm"><i
                    class="fa fa-pencil"></i>
                    {{ this.$installations_locale.global.active_installatione_edition }}
                </a>
                <a v-if="getActiveInstallationsEdition&&enableGeneralEdition" type="button" @click="saveItemEdited($event,installation_id)"
                   class="btn btn-primary btn-sm"><i
                    class="fa fa-save"></i>
                    {{ this.$installations_locale.global.buttons.save }}
                </a>
                <a v-if="getActiveInstallationsEdition&&enableGeneralEdition" type="button" @click="editItem($event,installation_id)"
                   class="btn btn-warning btn-sm"><i
                    class="fa fa-times"></i>
                    {{ this.$installations_locale.global.buttons.cancel }}
                </a>


                <button v-if="enableDuplicateBtn" class="btn btn-info btn-sm"
                        @click="duplicate()">
                    <i class="fa fa-copy mr-1"></i>
                    {{ duplicateTitle }}
                </button>
            </div>
            <div slot="name" slot-scope="{row}" v-if="getActiveInstallationsEdition" class="input-edit-zone"
                 style="display: inline-block">
                <input type="text" class="form-control" @input="setEditedItem(row.id,row.name)"
                       style="display: inline-block;width: 400px" v-model="row.name">
            </div>
            <span slot="name" slot-scope="{row}" v-else="getActiveInstallationsEdition">{{ row.name }}</span>
            <div slot="options" slot-scope="{row}">
                <button v-show="enableEditBtn" @click="editUser(row)" class="btn btn-xs btn-warning"><i
                    class="fa fa-pencil"></i></button>
                <button v-show="enableDelBtn" @click="deleteUser(row)" class="btn btn-xs btn-danger"><i
                    class="fa fa-trash"></i></button>
                <button v-if="btnConfig" @click="config(row)" class="btn btn-xs btn-primary"><i class="fa fa-cog"></i>
                </button>
                <button v-for="(item) in customOptions" @click="item.fn(item.params)" class="btn btn-xs btn-dark"><i
                    :class="'fa fa-'+item.icon"></i></button>
                <button v-if="$helpers.hasPermission('tasks.edit')&&enableDuplicateBtn" class="btn btn-info btn-xs"
                        @click="duplicate(row)"><i class="fa fa-copy"></i></button>
            </div>

            <template v-for="(item) in customFields">
                <div :slot="item.field">{{ item.content }}</div>
            </template>
        </v-client-table>
    </div>
</template>
<script>

import {mapGetters} from "vuex";

export default {
    name: 'InnoCoreDatatable',
    props: {
        selectable: {default: null, required: false},
        btnConfig: {default: false},
        columnsData: {required: true},
        idTable: {required: true},
        rowItems: {required: true},
        customFields: {required: false},
        customOptions: {required: false},
        enableCreateBtn: {default: true},
        enableDelBtn: {default: true},
        enableEditBtn: {default: true},
        enableDuplicateBtn: {default: false},
        enableGeneralEdition: {default: false},
        duplicateTitle: {default: ''},
        search: {required: false},
    },
    created() {
        this.setColumns()
        this.items = [...this.rowItems]
        this.$store.commit('installations/setEditedInstallations', [])
        this.$store.commit('installations/setActiveInstallationsEdition', false)

        // this.getData()
    },
    watch: {
        rowItems: function (newItems) {
            this.items = [...newItems]
        }
    },
    computed: {
        ...mapGetters('installations', ['getActiveInstallationsEdition', 'getEditedInstallations']),
    },
    data() {
        return {
            items: [],
            columns: [],

            options: {
                rowAttributesCallback: row => {
                    return {"data-id": row.id};
                },
                filterByColumn: true,
                resizableColumns: true,
                perPage: 10,
                perPageValues: [5, 10, 50, 100],
                pagination: {chunk: 5},
                datepickerOptions: {
                    showDropdowns: true,
                    autoUpdateInput: true,
                },
                sortIcon: {
                    base: 'fa float-right',
                    is: 'fa-sort',
                    up: 'fa-sort-up',
                    down: 'fa-sort-down'
                },
                texts: {
                    count: `${this.$t("Showing {from} to {to} of {count} records|{count} records|One record")}`,
                    first: `${this.$t("First")}`,
                    last: `${this.$t("Last")}`,
                    filter: `${this.$t("Filter")}:`,
                    filterPlaceholder: `${this.$t("Search query")}`,
                    limit: `${this.$t("Records")}:`,
                    page: `${this.$t("Page")}:`,
                    noResults: `${this.$t("No matching records")}`,
                    filterBy: `${this.$t("Filter by {column}")}`,
                    loading: `${this.$t("Loading")}...`,
                    defaultOption: `${this.$t("Select {column}")}`,
                    columns: `${this.$t("Columns")}`,
                },
                columnsDisplay: {},
                selectable: this.selectable ? this.selectable : null
                // columnsDropdown: true,
                // columnsDisplay: {
                //     email: 'min_tabletP',
                //     id: 'min_tabletP'
                // },
                // selectable:{
                //     mode: 'multiple', // or 'multiple'
                //     only: function(row) {
                //         return true // any condition
                //     },
                //     selectAllMode: 'all', // or 'page',
                //     programmatic: false
                // }
            }
        }
    },
    methods: {
        setColumns() {
            const headings = {}
            this.columnsData.map(item => {
                this.columns.push(item.field)
                headings[item.field] = item.label
                if (item.display) {
                    this.options.columnsDisplay[item.field] = item.display
                }
            })
            this.options.headings = headings

        },
        getData() {
            this.$refs.usersTable.setLoadingState(true)
            axios.get('/api-admin/admin/users').then(response => {
                const rows = response.data.data

                this.items = rows
                this.$refs.usersTable.setLoadingState(false)
            })
        },

        editUser(item) {
            this.$emit('edit', item)
        },
        deleteUser(item) {
            this.$emit('delete', item)
        },
        newItem() {
            this.$emit('new')
        },
        config(item) {
            this.$emit('config', item)
        },
        duplicate(item) {
            this.$emit('duplicateInstallation', item)
        },
        editItem(e, installation_id) {
            this.$store.commit('installations/setActiveInstallationsEdition', !this.getActiveInstallationsEdition)
        },
        saveItemEdited(e, installation_id) {
            this.$store.dispatch('installations/saveEditedInstallations')
        },
        setEditedItem(itemId, itemName) {
            let editedItems = this.getEditedInstallations
            let checkExist = null
            if (editedItems.length > 0) {
                checkExist = editedItems.find(item => item.id == itemId)
            }
            if (checkExist) {
                checkExist.name = itemName
            } else {
                editedItems.push({id: itemId, name: itemName})
            }

        }

    }

}
</script>
<style lang="scss">
.VueTables {
    label {
        margin-right: 10px;
    }
}

.VueTables__wrapper {
    max-height: 500px;
    overflow-y: scroll;
}

.VueTables__search-field {
    display: flex;
}

.VueTables__limit-field {
    display: flex;
}

.VueTables__columns-dropdown-wrapper {
    margin-right: 10px;
}

.VueTables__row {
    td {
        padding: 3px 5px;
        vertical-align: middle;
    }
}

.VueTables__columns-dropdown {
    input {
        margin-right: 3px;
    }
}
</style>
