<template>
	<div>
		<h4 v-if='show_title'>{{ this.$t('Consigns.consigns') }}</h4>

		<div v-if="show_alert" class='alert alert-warning mb-3'>
			<div class='d-inline-flex'>
					<i class='fa fa-triangle-exclamation fa-2x mr-3'></i>
					{{this.$t('Consigns.propagation_info')}}
			</div>
		</div>

		<div class='row'>
			<div class='col-12 col-xl-6 my-3'>
				<ConsignsForm :setted="currentConsigns" @save='appendConsign' />
			</div>

			<div class='col-12 col-xl-6 mb-2 mb-3'>
				<ul class='list-group'>
					<li v-for='(consign,i) in currentConsigns' :key='consign.id' class='list-group-item d-flex justify-content-between align-items-start'>
						<div class='ms-2 me-auto'>
							<div class='fw-bold'>{{ consign.reading_attribute }}</div>
							<span class='badge bg-primary rounded-pill'>{{ consign.evaluate }}</span>
						</div>

						<div class='consign-actions'>
							<i @click='remove(i)' class='fa fa-trash fa-xs text-danger mt-6 cursor-pointer'></i>
						</div>

					</li>
				</ul>
			</div>

			<slot></slot>

		</div>
	</div>

</template>
<script>

import ConsignsForm from './ConsignsForm'

export default {
	name: 'Consigns',
	props: {
		consigns: {required: false},
		show_title: {default: true},
		show_alert: {default: false, type: Boolean}
	},
	components: {
		ConsignsForm,
	},
	created() {
		if (this.consigns) {
			this.currentConsigns = [...this.consigns]
		}
	},
	watch: {
		consigns: function() {
			this.currentConsigns = [...this.consigns]
		},
	},
	data() {
		return {
			consign: {
				attribute: null,
				evaluate: '',
			},
			currentConsigns: [],
		}
	},
	methods: {

		remove(index) {
			const position = this.currentConsigns.findIndex((item, i) => i === index)
			this.currentConsigns.splice(position, 1)
			this.$emit('setConsigns', this.currentConsigns)
		},
		appendConsign(data) {
			this.currentConsigns.push(data)
			this.$emit('setConsigns', this.currentConsigns)
		},
	},
}
</script>
<style scoped lang='scss'>
.consign-actions {
	position: absolute;
	right: 10px;
	bottom: 5px;
}
</style>