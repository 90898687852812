<template>
    <div>
        <modal :show="show" @close="close">
            <div class="base col-lg-12 col-12" >
                <div class="w-100 text-center">
                    <span class="h4 font-weight-bold">Selecciona los puntos de control.</span>
                </div>
                <VueTree
                    ref="tree1"
                    showCheckbox
                    expandedAll
                    :treeData="this.treeData"
                    @on-checked-item="checkedItem"
                    @on-selected-change="clickNode"
                    @on-checked-change="clickCheckbox"
                />

            </div>

        </modal>
    </div>
</template>

<script>
import Modal from './Modal'
import VueTree  from 'light-vue-tree';

export default {
    name: "CheckpointTreeModal",
    components: {
        Modal,
        'VueTree': VueTree,
    },
    data(){
      return {
          treeData:[],
      }
    },
    props: ['show'],
    mounted() {
        this.setZonesCheckboxTree()
    },
    methods:{
        close: function() {
            this.$emit('close')
        },

        checkedItem({ node, vNode }) {

        },
        clickNode(node) {

        },
        clickCheckbox({ node, selectedData }) {

        },
        setZonesCheckboxTree(){
            axios.get('/api-admin/installations/2/zones/treeVue')
                .then((res)=>{
                    this.treeData=res
                })
                .catch((err)=>{

                })
        },
    }
}
</script>

<style lang="scss">

</style>
