<template>
    <card-template>
        <template slot="titleCard">{{ this.$alarms_locale.global.alarm_detail }}</template>
        <div class="row">
            <div class="col-xs-12 col-sm-6 mb-2">
                <div class="col-12 my-2 p-0">
                    <span class="">{{ this.$alarms_locale.global.alarm_detail }} </span>
                </div>
                <div class="col-12 mb-2">
                    <span class="">{{ this.$alarms_locale.global.alarm }}: </span>
                    <b class="">#{{ this.alarm.id }}</b>
                </div>
                <!--                <div class="col-12 mb-2">-->
                <!--                    <span class="">{{ this.$alarms_locale.global.table_columns.alarm_date }}: </span>-->
                <!--                    <b class="">{{this.alarm.alarm_date}}</b>-->
                <!--                </div>-->
                <div class="col-12 mb-2">
                    <span class="">{{ this.$alarms_locale.global.table_columns.updated_date }}: </span>
                    <b class="">{{ this.alarm.updated_at }}</b>
                </div>

                <div class="col-12 mb-2">
                    <span class="">{{ this.$alarms_locale.global.table_columns.installation }}: </span>
                    <b class="">{{ this.alarm.installation }}</b>
                </div>
                <div class="col-12 mb-2">
                    <span class="">{{ this.$alarms_locale.global.table_columns.zone }}:</span>
                    <b class=""> {{ this.alarm.full_path_zone }}</b>
                </div>
                <div class="col-12 mb-2">
                    <span class="">{{ this.$alarms_locale.global.table_columns.item }}:</span>
                    <b class=""> {{ this.alarm.item_name }}</b>
                </div>
                <div class="col-12 mb-2">
                    <span class="">{{ this.$alarms_locale.global.table_columns.reading_key }}:</span>
                    <b class=""> {{ this.alarm.reading_key_value }}</b>
                </div>
                <div class="col-12 mb-2">
                    <span class="">{{ this.$alarms_locale.global.table_columns.condition_consign }}:</span>
                    <b class=""> {{ this.alarm.consign_value }}</b>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 mb-2 ">
                <div class="col-12  mb-2 d-flex align-items-center">
                    <span class="mr-1">{{ this.$alarms_locale.global.table_columns.state }}:</span>
                    <div class="w-50">
                        <Select2 v-model="formData.alarm_state_id" :options="parseAlarmStates(alarm_states)"
                                 :settings="{width:'60%' }"/>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <label for="commentsTask" class="">{{
                            this.$alarms_locale.global.table_columns.comments
                        }}</label>
                    <textarea id="commentsAlarm" class="form-control"
                              v-model="formData.comments"></textarea>
                </div>
            </div>
        </div>
        <div class="col-12">
            <button @click="save" class="btn btn-primary">{{ this.$alarms_locale.global.buttons.update }}</button>
            <button @click="cancel" class="btn btn-dark">{{ this.$alarms_locale.global.buttons.cancel }}</button>
        </div>
        <hr>
        <div class="col-12 mt-4 mx-0 p-0">
            <span >{{ this.$alarms_locale.global.reading_history }}</span>
            <reading-table
                :type="this.alarm.reading_type"
                :enableCreateBtn="false"
                :idTable="idTable"
                :readingsId="this.alarm.readings_id"
                :columnsData="columns"
                :alarmData="this.alarm"
                :customFilters="customFilters"
                :rowItems="items"
                :customFields="customFields"
                :btnConfig="false"
                @edit="editItem"
                @delete="deleteItem"
            ></reading-table>
        </div>
    </card-template>
</template>
<script>
import Select2 from 'v-select2-component';

export default {
    name: 'AlarmForm',
    props: ['alarm', 'alarm_states'],
    components: {
        Select2
    },
    created() {

    },
    data() {
        return {
            formData: {
                id: this.alarm.id,
                alarm_state_id: this.alarm.alarm_state_id,
                comments: this.alarm.comments
            },
            customFields: [
            ],
            items:this.alarm.readings,
            idTable: 'readingsTable',
            columns:[
                {
                    "field": "id",
                    "label": "#",
                    "display": "min_tabletP"
                },
                // {
                //     "field": "type",
                //     "label": this.$readings_locale.global.table_columns.type
                // },
                {
                    "field": "reading_date",
                    "label": this.$readings_locale.global.table_columns.reading_date
                },
                {
                    "field": "installation_name",
                    "label": this.$readings_locale.global.table_columns.installation
                },{
                    "field": "item_name",
                    "label": this.$readings_locale.global.table_columns.item
                },
                {
                    "field": "reading_value",
                    "label": this.$readings_locale.global.table_columns.reading_value
                },
                {
                    "field": "options",
                    "label": this.$readings_locale.global.table_columns.detail
                }
            ],
            customFilters: [
                'date_range'
            ],
        }
    },
    computed: {},
    methods: {
        parseAlarmStates(states) {
            let result = [];
            states.forEach((v) => {
                result.push({id: v.id, text: this.$alarms_locale.global.states_trans[v.name]})
            })
            return result
        },
        save() {
            let formData = new FormData()
            Object.keys(this.formData).map(field => {
                formData.append(field, this.formData[field] === null ? '' : this.formData[field])
            })
            axios.post('/api-admin/alarms', formData, {headers: {'content-type': 'multipart/form-data'}})
                .then(response => {
                    this.$helpers.showLoading()
                    this.$helpers.toastSuccess()
                    setTimeout(() => {
                        window.location = `/alarms/${this.alarm.id}`
                    }, 1000)
                })
        },
        cancel: ()=>{
            window.location=`/alarms/`

        },
        editItem(item) {
            window.location = `/readings/${item.id}`
        },
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDelete', {
                text: `${this.$readings_locale.global.modals.delete_task} ${item.name} `,
                parameters: item
            })
        },
    }

}
</script>

