<template>
	<div v-if="this.item" class='row'>
		<div class='col-4 font-weight-bold'>{{this.item.attribute.label}}</div>
		<div class='col-4'>
			<v-select
			:options='operators'
			v-model='operator'
			label='label'
			code='value'
			@input='setItem'
			></v-select>
		</div>
		<div class='col-4'>
			<div class='row'>
				<div class='col-10'>
					<input @change="setItem" type='text' v-model='value' class=' form-control'>
				</div>
				<div class='col-2'>
					<i @click="removeItem" class='fa fa-trash fa-sm text-danger cursor-pointer'></i>
				</div>
			</div>


		</div>

	</div>

</template>
<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
	name: 'ConsignEvaluate',
	props: ['item','index'],
	components: {
		vSelect,
	},
	created() {

	},
	watch:{
		item: function (newItem){
			this.operator = newItem.operator
			this.value = newItem.value
		}
	},
	data() {
		return {
			operator: null,
			value: null,
			operators: [
				{
					value: '>',
					label: 'Mayor que',
				},
				{
					value: '>=',
					label: 'Mayor o igual a',
				},
				{
					value: '<',
					label: 'Menor a',
				},
				{
					value: '<=',
					label: 'Menor o igual a',
				},
				{
					value: '==',
					label: 'Igual a',
				},
				{
					value: '!=',
					label: 'Diferente a',
				},
			],
		}
	},
	methods: {
		removeItem(){
			this.$emit('remove',this.index)
		},
		setItem(){
			this.$emit('set',{
				index: this.index,
				operator:this.operator,
				value: this.value
			})
		}
	},
}
</script>
