<template>
  <div>
    <div class=" table-responsive">
      <div class="mb-3 text-right">
        <a v-if="!getActiveAccumulatorsEdition" type="button" @click="editAccumulators($event,installation_id)"
           class="btn btn-warning btn-sm"><i
            class="fa fa-pencil"></i>
          {{ this.$installations_locale.global.active_accumulator_edition }}
        </a>
        <a v-if="getActiveAccumulatorsEdition" type="button" @click="saveAccumulatorsEdited($event,installation_id)"
           class="btn btn-primary btn-sm"><i
            class="fa fa-save"></i>
          {{ this.$installations_locale.global.buttons.save }}
        </a>
        <a v-if="getActiveAccumulatorsEdition" type="button" @click="editAccumulators($event,installation_id)"
           class="btn btn-warning btn-sm"><i
            class="fa fa-times"></i>
          {{ this.$installations_locale.global.buttons.cancel }}
        </a>
        <button @click="copyAccumulator()" class="btn btn-sm btn-info"><i class="fa fa-copy"></i>
          {{ this.$installations_locale.global.copy_selected_acc }}
        </button>
        <button @click="newItem" class="btn btn-sm btn-success"><i class="fa fa-plus"></i>
          {{ this.$installations_locale.global.new_accumulator }}
        </button>
      </div>


      <table class="table">
        <thead>
        <tr>
          <th><input type="checkbox" name="select-all" class="select-all"></th>
          <th :key="column.field" v-for="column in columns">{{ column.label }}</th>
        </tr>
        </thead>
        <tbody>
        <tr :key="item.id" v-for="item in accumulators">
          <td><input type="checkbox" @input="setSelectedAcc($event,item.id)" :name="'select-'+item.id"
                     class="check-acc"></td>
          <td>{{ item.id }}</td>
          <td v-if="getActiveAccumulatorsEdition" class="input-edit-acc" style="display: inline-block">
            <input type="text" class="form-control"  @input="setEditedAcc(item.id,item.code)" style="display: inline-block;width: 400px" v-model="item.code" >
          </td>
          <td v-else="getActiveAccumulatorsEdition">{{ item.code }}</td>
          <td>{{ item.function_label }}</td>
          <td>{{ item.registry_label }}</td>
          <td>{{ item.temperature_control }}</td>
          <td>
            <button @click="editItem(item)" class="btn btn-xs btn-warning"><i class="fa fa-pencil"></i>
            </button>
            <button @click="deleteItem(item)" class="btn btn-xs btn-danger"><i class="fa fa-trash"></i>
            </button>
            <button @click="copyAccumulator(item.id)" class="btn btn-xs btn-info"><i class="fa fa-copy"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Accumulators",
  props: {
    accumulators: {type: Array},
    circuit_id: {required: true},
    installation_id: {required: true},
    refresh_fn: {}
  },
  data() {
    return {
      idTable: 'AccumulatorsTable',
      columns: [
        {
          field: 'id',
          label: '#',
          display: 'min_tabletP',
        },
        {
          field: 'code',
          label: this.$installations_locale.global.code,
        },
        {
          field: 'function',
          label: this.$installations_locale.global.function,
        },
        {
          field: 'registry',
          label: this.$installations_locale.global.registry,
        },
        {
          field: 'temperature_control',
          label: this.$installations_locale.global.temperature,
        },
        {
          field: 'options',
          label: this.$installations_locale.global.options
        }
      ]
    }
  },
  computed:{
    ...mapGetters('circuits',['getActiveAccumulatorsEdition','getEditedAcc'])
  },
  created() {
    this.$store.commit('circuits/setSelectedAcc', [])
    this.$store.commit('circuits/setActiveAccumulatorsEdition',false)

  },
  methods: {
    editItem(item) {
      // window.location = `/installations/${this.installation_id}/config/circuits/${this.circuit_id}/accumulators/${item.id}`
      window.open(`/installations/${this.installation_id}/config/circuits/${this.circuit_id}/accumulators/${item.id}`, '_blank')

    },
    deleteItem(item) {
      this.$bus.$emit('fireModalConfirmDeleteFn', {
        text: `${this.$installations_locale.global.modals.delete_accumulator} ${item.code} `,
        parameters: item,
        fn: this.removeAccumulator
      })
    },

    removeAccumulator(item) {
      const url = `/api-admin/installations/${this.installation_id}/circuits/${item.circuit_id}/accumulators/${item.id}`
      axios.delete(url).then(response => {
        this.$toast.success(this.$t('toast.delete_success'))
        this.refresh_fn(response.data)
      })
    },

    newItem() {
      // window.location = `/installations/${this.installation_id}/config/circuits/${this.circuit_id}/accumulators`
      window.open(`/installations/${this.installation_id}/config/circuits/${this.circuit_id}/accumulators`, '_blank')
    },
    copyAccumulator(accId = null) {
      //  let accIds = this.$refs.AccumulatorsTable.selectedRows.map(item => item.id)
      let accIds = []
      if (accId) {
        accIds = [accId]
      } else {
        accIds = this.$store.getters['circuits/getSelectedAcc']
      }
      this.$swal.fire({
        title: this.$installations_locale.global.duplicate_accumulator,
        text: this.$installations_locale.global.duplicate_copies_amount,
        input: 'number',
        inputAttributes: {
          autocapitalize: 'off',
          placeholder: this.$installations_locale.global.duplicate_copies_amount_placeholder
        },
        showCancelButton: true,
        confirmButtonText: this.$installations_locale.global.buttons.success,
        cancelButtonText: this.$installations_locale.global.buttons.cancel,
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading()
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$store.dispatch('circuits/copyAccumulators', {acc_ids: accIds, copies_number: result.value})
          this.$store.dispatch('circuits/getCircuits', this.installation_id)
          $('.check-acc:checked').prop('checked', false)
        }
      })
    },
    setSelectedAcc(e, accId) {

      const selected = this.$store.getters['circuits/getSelectedAcc']

      if ($(e.target).is(':checked')) {
        selected.push(accId)
      } else {
        const index = selected.findIndex(item => item == accId)
        selected.splice(index, 1)
      }
      this.$store.commit('circuits/setSelectedAcc', selected)
    },
    editAccumulators() {
      this.$store.commit('circuits/setActiveAccumulatorsEdition', !this.getActiveAccumulatorsEdition)

    },
    saveAccumulatorsEdited() {
      this.$store.dispatch('circuits/saveEditedAccumulators', this.installation_id)
    },
    setEditedAcc(accId,accCode){
      let editedAcc = this.getEditedAcc
      let checkExist = null
      if(editedAcc.length > 0){
        checkExist = editedAcc.find(item => item.id == accId)
      }
      if(checkExist){
        checkExist.code = accCode
      }else{
        editedAcc.push({id:accId,code:accCode})
      }
    }
  }
}
</script>
