export default {
		current: null,
		list_url: '/installations/:installation_id/zones',
		create_url: '/installations/:installation_id/zones/new',
		edit_url: '/installations/:installation_id/zones/:zone_id',
        zones:[],
		selected_zones:[],
        active_zones_edition:false,
        active_checkpoints_edition:false,
        edited_zones:[],
        edited_checkpoints:[]

}
