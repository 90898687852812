<template>
    <div>
        <button class="btn btn-light " @click="goToViewAnalysis"><i class="fa fa-chart-pie mr-1"></i>Ver análisis</button>
    </div>
</template>

<script>
export default {
    name: "ButtonAnalysis",
    props: {installation : {default: null}},
    methods: {
        goToViewAnalysis() {
            window.open('/dashboard/analysis?installation=' + this.installation,'_blank')
        }
    }
}
</script>

<style scoped>

</style>
