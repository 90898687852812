<template>
	<div>
		<v-client-table :ref='idTable' :data='items' :columns='columns' :options='options'>
			<div slot='afterFilterWrapper' style='margin-left:10px;align-items: center;display: flex;margin-bottom: 0;'>
				<div class='d-flex'>
					<div class='d-flex align-items-center mr-2'>
						<label>{{ this.$tasks_locale.global.table_columns.state }}:</label>
						<task-filter :states='states' @setFilter='setFilter' :current='currentFilter' class='my-2'
									 :refresh_fn='refreshData'></task-filter>
					</div>

					<div class='pt-1'>
						<button v-if="$helpers.hasPermission('tasks.edit')" class='btn btn-excel'
								style='background-color: #1D6F42;color:white;' @click='exportIncompleteTasks()'>
							<i class='fa fa-file-excel mr-1'></i>
							{{ this.$tasks_locale.global.incomplete_tasks_export }}
						</button>
						<button v-if="$helpers.hasPermission('tasks.edit')" class='btn btn-info'
								@click='duplicateSelectedItems()'>
							<i class='fa fa-copy mr-1'></i>
							{{ this.$tasks_locale.global.duplicate_tasks }}
						</button>
					</div>

				</div>
			</div>
			<div slot='beforeTable'>
				<div class='text-right'>
					<button v-if="this.$helpers.hasPermission('tasks.edit')" @click='newItem'
							class='btn btn-primary btn-sm mb-2'><i class='fa fa-plus'></i>
						{{ this.$tasks_locale.global.buttons.new }}
					</button>
				</div>
			</div>
			<template v-for='(item) in customFields'>
				<div v-html :slot='item.field' v-html='item.content'> {{ item.content }}</div>
			</template>
			<div slot='info' class='row' slot-scope='{row}'>
				<span class='col-12'>{{ row.total_readings }} lecturas</span>
				<span class='col-12'>{{ row.percent_complete }}% tarea realizada</span>
			</div>
			<div slot='items_count' class='row' slot-scope='{row}'>
				<span class='col-12'>{{ row.items_count }}</span>
				<span class='col-12'>{{ row.total_events }} eventos</span>
			</div>
			<!--            <template slot='format_date' slot-scope='{row}'>{{ moment(row.format_date) }}</template>-->
			<!--            <div slot='format_expiration_date' slot-scope='{row}'>-->
			<!--                <span>{{ parseDate(row.format_expiration_date) }}</span>-->
			<!--            </div>-->
			<div slot='ac_icon' slot-scope='{row}' class='text-center' v-html='row.ac_icon'></div>
			<div slot='af_icon' slot-scope='{row}' class='text-center' v-html='row.af_icon'></div>
			<div slot='mix_icon' slot-scope='{row}' class='text-center' v-html='row.mix_icon'></div>
			<!--            <div slot='afterFilterWrapper' style="margin-left:10px;align-items: center;display: flex;margin-bottom: 0;">-->
			<!--                -->
			<!--            </div>-->
			<div slot='options' slot-scope='{row}'>
				<button v-if="$helpers.hasPermission('tasks.edit')" @click='editItem(row)'
						class='btn btn-xs btn-warning'><i class='fa fa-pencil'></i></button>
				<button v-if="$helpers.hasPermission('tasks.delete')" @click='deleteItem(row)'
						class='btn btn-xs btn-danger'><i class='fa fa-trash'></i></button>
				<button v-if='btnConfig' @click='config(row)' class='btn btn-xs btn-primary'><i class='fa fa-cog'></i>
				</button>
				<button :title='restore_label' v-if='row.disabled === true' @click='restore(row)'
						class='btn btn-xs btn-info'>
					<i class='fa fa-sync'></i></button>
				<button v-if="$helpers.hasPermission('tasks.edit')" class='btn btn-info btn-xs'
						@click='duplicateTask(row)'><i class='fa fa-copy'></i></button>

			</div>
			<div slot='optionsResponsive' slot-scope='{row}'>
				<button @click='editItem(row)' class='btn btn-xs btn-warning'><i class='fa fa-pencil'></i></button>
				<button @click='deleteItem(row)' class='btn btn-xs btn-danger'><i class='fa fa-trash'></i></button>
				<button @click='showDetail(row)' class='btn btn-xs btn-primary px-2'>
					<i class='fa text-white fa-info-circle'></i>
				</button>
			</div>

			<template #format_date="{row}">
				{{ row.format_date.format('DD/MM/YYYY') }}
			</template>

			<template #format_expiration_date="{row}">
				{{ row.format_expiration_date.format('DD/MM/YYYY') }}
			</template>

		</v-client-table>
	</div>
</template>

<script>
import TaskFilter from './TaskFilter'
import moment from 'moment'
import {getContent, selectOption} from './SwalDuplicateFormOptions'
import {datatables_options} from '../../../../../resources/js/datatables_data'

export default {
	name: 'TaskTable',
	props: {
		btnConfig: {default: false},
		columnsData: {required: true},
		idTable: {required: true},
		rowItems: {required: true},
		customFields: {required: false},
		enableCreateBtn: {default: true},
		states: {required: true},
		is_admin: {required: true},
		// optionsTable: {required: true},
		// zone_id: {required: true},
	},
	computed: {
		restore_label: function() {
			return this.$installations_locale.global.restore_checkpoint
		},
	},
	components: {
		TaskFilter,
	},
	created() {

		this.setColumns()
		this.items = [...this.rowItems]
	},
	watch: {
		rowItems: function(newItems) {
			this.items = [...newItems]
		},
	},
	data() {
		return {
			items: [],
			columns: [],
			currentFilter: {
				key: 1,
				label: 'Abierta',
			},
			options: {
				...datatables_options,
				filterable : ['id','name','type','installation_name','format_date','format_expiration_date']
			},
		}
	},
	methods: {
		// parseDate(date) {
		//     // moment.locale('es')
		//     return moment(date).format('DD/MM/YYYY')
		// },
		setColumns() {
			const headings = {}
			this.columnsData.map(item => {
				this.columns.push(item.field)
				headings[item.field] = item.label
				if (item.display) {
					this.options.columnsDisplay[item.field] = item.display
				}
			})
			this.options.headings = headings
			$('.VueTables__filter-placeholder').append('<i class="fa fa-calendar"></i>')

		},
		exportIncompleteTasks() {
			let data = this.$refs.tasksTable.allFilteredData
			axios.post('/api-admin/tasks/exportIncompleteTask', data).then((res) => {
				const blob = new Blob([res], {type: 'text/csv'})
				const link = document.createElement('a')
				link.href = URL.createObjectURL(blob)
				link.download = `tasks_${this.formatDate(new Date())}.csv`
				link.click()
				URL.revokeObjectURL(link.href)
			})
		},
		refreshData(items) {
			this.items = items
		},
		formatDate(date) {
			return [
				this.padTo2Digits(date.getDate()),
				this.padTo2Digits(date.getMonth() + 1),
				date.getFullYear(),
			].join('/')
		},
		padTo2Digits(num) {
			return num.toString().padStart(2, '0')
		},
		editItem(item) {
			this.$emit('edit', item)
		},
		deleteItem(item) {
			this.$emit('delete', item)
		},
		newItem() {
			this.$emit('new')
		},
		config(item) {
			this.$emit('config', item)
		},

		setFilter(filter) {
			this.currentFilter = filter
			this.filterResults()
		},

		filterResults() {
			console.log(this.currentFilter)
			const filter = this.currentFilter ? {task_status: this.currentFilter.key} : {}
			const queryParams = this.$helpers.setUrlParameters(filter)
			axios.get(`/api-admin/tasks/getTasks${queryParams}`).then(response => {
				const rows = response.data
				rows.map(item => {
					item.format_date = moment(item.format_date)
					item.format_expiration_date = moment(item.format_expiration_date)
				})
				this.items = rows
				this.refreshData(rows)
			})
		},

		restore(item) {
			axios.get(`/api-admin/tasks/getTasks/restore`).then(response => {
				this.filterResults()
			})
		},
		duplicateSelectedItems(taskId = null) {
			let taskIds = this.$refs.tasksTable.selectedRows.map(item => item.id)
			if (taskId) {
				taskIds.push(taskId)
			}
			this.$swal.fire({
				title: this.$tasks_locale.global.duplicate_task_modal_title,
				text: this.$tasks_locale.global.duplicate_task_extra_days_title,
				html: getContent(taskIds, this.duplicateContentTraslations()),

				showCancelButton: true,
				confirmButtonText: this.$tasks_locale.global.buttons.success,
				cancelButtonText: this.$tasks_locale.global.buttons.cancel,
				showLoaderOnConfirm: true,
				preConfirm: () => {
					const copiesNumber = document.getElementById('copies-number').value
					const optionSelected = document.querySelector('.btn-option.active').getAttribute('data-option')
					const optionSelectedAmount = document.getElementById('option-selected-amount').value
					return Promise.resolve({copiesNumber, optionSelected, optionSelectedAmount})
				},
				allowOutsideClick: () => !this.$swal.isLoading(),
			}).then(async (result) => {
				if (result.isConfirmed) {
					console.log(result)
					await this.$store.dispatch('tasks/duplicateZones', {
						task_ids: taskIds,
						copies_number: result.value.copiesNumber,
						options_selected: result.value.optionSelected,
						options_selected_amount: result.value.optionSelectedAmount,
					})
					this.$refs.tasksTable.resetSelectedRows()
					this.filterResults()
				}

			})
		},

		duplicateContentTraslations() {
			const trans = [
				{'duplicate_copies_number': this.$tasks_locale.global.duplicate_copies_number},
				{'duplicate_options_copy': this.$tasks_locale.global.duplicate_options_copy},
				{'amount_option_copy': this.$tasks_locale.global.amount_option_copy},
				{'to_add': this.$tasks_locale.global.to_add},
				{'days': this.$tasks_locale.global.days},
				{'months': this.$tasks_locale.global.months},
				{'years': this.$tasks_locale.global.years},
			]
			const transObject = trans.reduce((acc, item) => {
				const key = Object.keys(item)[0] // Obtener la clave del objeto
				const value = item[key] // Obtener el valor del objeto
				acc[key] = value // Asignar la clave y el valor al objeto acumulador
				return acc
			}, {})
			return transObject
		},
		duplicateTask(row) {
			this.duplicateSelectedItems(row.id)
		},
	},
}
</script>

<style lang='scss'>

tr.VueTables__row.disabled-item {
	background-color: #ffe5e5 !important;
}

.VueTables {
	label {
		margin-right: 10px;
	}
}

.VueTables__wrapper {
	max-height: 500px;
	overflow-y: scroll;
}

.VueTables__search-field {
	display: flex;
}

.VueTables__limit-field {
	display: flex;
}

.VueTables__columns-dropdown-wrapper {
	margin-right: 10px;
}

.VueTables__row {
	td {
		padding: 3px 5px;
		vertical-align: middle;
	}
}

.VueTables__columns-dropdown {
	input {
		margin-right: 3px;
	}
}

.VueTables__filter-placeholder {
	cursor: pointer;
}

</style>
