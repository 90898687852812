<template>
    <div class="w-100">
        <apex-chart ref="apexChart" :height="height" :options="chart_options" :series="series"/>
    </div>
</template>
<script>
import ApexChart from 'vue-apexcharts'

export default {
    name: 'ChartApexMultiple',
    props: {
        id: {
            required: true
        },
        height: {},
        chart_type: {
            type: String,
            default: 'area' //area, line, column, pie
        },
        xaxis_type: {
            type: String,
            default: 'category' //category, datetime, numeric
        },
        series: {
            type: Array,
            required: true
        },
        show_guide: {
            type: Boolean,
            default: false
        },
        guide_value: {
            type: Number,
        },
        guide_label: {
            type: String,
            default: ''
        }
    },
    components: {
        ApexChart
    },
    created() {
        if (this.show_guide) {
            this.chart_options.annotations = {
                yaxis: [
                    {
                        y: this.guide_value,
                        borderColor: '#e3003d',
                        label: {
                            borderColor: '#e3003d',
                            style: {
                                color: '#fff',
                                background: '#e3003d'
                            },
                            text: this.guide_label
                        }
                    }
                ]
            }
        }
    },
    mounted() {
        this.series.forEach((item, key) => {
            key != 0 ? this.$refs.apexChart.chart.toggleSeries(item.name) : ''
        })
    },
    methods: {
        updateSeriesLine() {
            this.$refs.apexChart.updateSeries([{
                data: this.series[0].data,
            }], false, true);
        },
    },
    data() {
        return {
            chart_options: {
                colors:['#F44336', '#E91E63', '#9C27B0','#40407a','#34ace0','#ff793f','#218c74','#b33939','#f1c40f','#2c3e50','#d35400','#27ae60','#fd79a8','#4b4b4b'],
                chart: {
                    id: this.id,
                    height: 350,
                    type: "line",
                    zoom: {
                        autoScaleYaxis: true
                    }
                },
                dataLabels: {
                    enabled: true
                },
                markers: {
                    size: 2,
                    style: 'hollow',
                },

                legend: {
                    fontSize: "16px",

                    labels: {
                        colors: "#b2b2b2",
                        useSeriesColors: false
                    }
                },

                stroke: {
                    curve: "smooth"
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: ["#000000"]
                        }
                    }
                },
                xaxis: {

                    type: this.xaxis_type,
                    tickAmount: 6,
                    labels: {
                        format: 'dd/MM',
                        show: true,
                        rotate: -45,
                        rotateAlways: false,
                    }

                },
                tooltip: {
                    x: {
                        format: "dd/MM/yy HH:mm"
                    }
                },

            },
        }
    },
}
</script>
