<template>
    <div class="w-100">
        <apex-chart type="pie" :options="chartOptions" :series="series"></apex-chart>
    </div>
</template>
<script>
import ApexChart from 'vue-apexcharts'

export default {
    name: "LegionellaChartDonut",
    components: {
        ApexChart
    },
    props: {
        series: {required: true}
    },


    data() {
        return {
            items: [],
            chartOptions: {
                chart: {
                    type: 'pie',
                },
                legend: {
                    show: true,
                    position: "bottom"
                },
                labels: ['Positivo(Incorrecto)', 'Presencia(Aceptable)', 'Negativa(Correcto)'],
                colors:['rgba(251,0,29,0.6)', '#FEB019', '#00e396'],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 300
                        },
                        legend: {
                            show: false
                        }
                    }
                }]
            },
        }
    },

}
</script>

<style scoped>

</style>
