<template>
    <div>
        <InstallationCombo @add="setInstallation" :clearable="clearable" :installation_id="current_installation" :selectFirst="true"  :label="this.$installations_locale.global.change_installation" ></InstallationCombo>
    </div>
</template>

<script>
import InstallationCombo from '../../../../Modules/Installations/Resources/assets/components/InstallationsCombo'
export default {
    name: "InstallationFilter",
    components: {
        InstallationCombo
    },
    props:{current_installation:{default:null}, clearable:{default:true}},
    created() {

    },
    methods: {
        setInstallation(installation){
            this.$emit('installationChanged',installation)
        }
    }
}
</script>

<style scoped>

</style>
