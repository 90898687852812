export const datatables_options = {
	filterByColumn: true,
	resizableColumns: true,
	perPage: 10,
	perPageValues: [5, 10, 50, 100],
	pagination: {chunk: 5},
	datepickerOptions: {
		showDropdowns: true,
		autoUpdateInput: true,
	},
	sortIcon: {
		base: 'fa float-right',
		is: 'fa-sort',
		up: 'fa-sort-up',
		down: 'fa-sort-down'
	},
	texts: {
		count: `${window.$t("Showing {from} to {to} of {count} records|{count} records|One record")}`,
		first: `${window.$t("First")}`,
		last: `${window.$t("Last")}`,
		filter: `${window.$t("Filter")}:`,
		filterPlaceholder: `${window.$t("Search query")}`,
		limit: `${window.$t("Records")}:`,
		page: `${window.$t("Page")}:`,
		noResults: `${window.$t("No matching records")}`,
		filterBy: `${window.$t("Filter by {column}")}`,
		loading: `${window.$t("Loading")}...`,
		defaultOption: `${window.$t("Select {column}")}`,
		columns: `${window.$t("Columns")}`,
	},
	columnsDisplay: {},
	// selectable: this.selectable ? this.selectable : null
	// columnsDropdown: true,
	// columnsDisplay: {
	//     email: 'min_tabletP',
	//     id: 'min_tabletP'
	// },
	// selectable:{
	//     mode: 'multiple', // or 'multiple'
	//     only: function(row) {
	//         return true // any condition
	//     },
	//     selectAllMode: 'all', // or 'page',
	//     programmatic: false
	// }
	//filterable: ['id'], // array of columns or false or true
}