<template>
   <div  v-show="series.length > 0" class="text-center p-2 w-100">
       <div class="shadow">
           <span class="w-100 text-center">{{this.title}}</span>
           <chart-apex-multiple
               height="450px"
               id="HistoryChart"
               width="100%"
               chart_type="line"
               xaxis_type="datetime"
               :series="series"
           />
       </div>
   </div>
</template>
<script>
import ChartApexMultiple from '/../resources/js/components/Charts/ChartApexMultiple'

export default {
    name: "ReadingsItemChart",
    components: { ChartApexMultiple },
    props: {
        item: {
            required: true
        },
        title:{
            type:String,
            default:'-'
        }

    },
    created() {
        this.series = this.item
    },
    watch: {
        item() {
            this.series = this.item
        }
    },
    data() {
        return {
            series: [{
                name: 'series-1',
                data: []
            }]
        }
    }
}
</script>

<style scoped>

</style>
