<template>
    <card-template>
        <template slot="titleCard">{{ this.$installations_locale.global.installations_list }}</template>
        <div class="table-responsive">
            <inno-core-datatable
                ref="innoCoreTable"
                :enableCreateBtn="this.$helpers.hasPermission('installations.edit')"
                :enableEditBtn="this.$helpers.hasPermission('installations.edit')"
                :enableDelBtn="this.$helpers.hasPermission('installations.delete')"
                :enableDuplicateBtn="this.$helpers.hasPermission('installations.edit')"
                :enableGeneralEdition="this.$helpers.hasPermission('installations.edit')"
                :duplicateTitle="this.$installations_locale.global.duplicate_installation_title"
                :idTable="idTable"
                :columnsData="columns"
                :selectable="selectable"
                :rowItems="items"
                :customFields="customFields"
                :customOptions="this.$helpers.hasPermission('installations.edit')?customOptions:''"
                :btnConfig="this.$helpers.hasPermission('installations.edit')"
                @edit="editItem"
                @delete="deleteItem"
                @new="newItem"
                @config="setItem"
                @duplicateInstallation="duplicateInstallation"
            >
            </inno-core-datatable>
        </div>
        <modal-confirm-delete @confirmed="removeItem"/>

    </card-template>
</template>
<script>
import {mapGetters} from "vuex";
import {getContent} from "./SwalDuplicateFormOptions";

export default {
    name: 'InstallationsList',
    created() {
        this.$store.commit('installations/removeCurrent')
        this.getItems()
        this.items = this.getInstallations
        this.$store.dispatch('customers/getCustomers')
    },

    data() {
        return {
            customers: [],
            idTable: 'installationsTable',

            items: [],
            customOptions: [
                {fn: this.openCreateTask, icon: 'tasks', params: ''}
            ],
            customFields: [
                // {field: 'options', content: ''}
            ],
            selectable: {
                mode: 'multiple',
                only: function (row) {
                    return true
                },
                selectAllMode: 'all',
                programmatic: false
            },
            columns: [
                {
                    field: 'id',
                    label: '#',
                    display: 'min_tabletP',
                    target: '1'
                },
                {
                    field: 'name',
                    label: this.$installations_locale.global.name,
                },
                {
                    field: 'customer_name',
                    label: this.$installations_locale.global.customer,
                },
                {
                    field: 'options',
                    label: this.$installations_locale.global.options
                }
            ]
        }
    },
    computed: {
        ...mapGetters('installations', ['getInstallations']),
        ...mapGetters('customers', ['getCustomers'])
    },
    watch: {
        getInstallations() {
            this.items = this.getInstallations
        }
    },
    methods: {
        openCreateTask() {
            let installationId = $(event.target).closest('tr').attr('data-id');
            window.open('/tasks/create?i=' + installationId, '_blank')
        },
        getItems() {
            this.$store.dispatch('installations/getInstallations')
        },
        editItem(item) {
            this.$store.commit('installations/setCurrent', item);
            window.open(`/installations/${item.id}`, '_blank')

        },
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDelete', {
                text: `${this.$installations_locale.global.modals.delete_installation} ${item.name} `,
                parameters: item
            })
        },
        removeItem(item) {
            axios.delete(`/api-admin/installations/${item.id}`).then(response => {
                const rows = response.data
                this.items = rows
                this.$toast.success(this.$t('toast.delete_success'))
            })
        },
        newItem() {
            window.open('/installations/create', '_blank')

        },
        setItem(item) {
            this.$store.commit('installations/setCurrent', item);
            window.open(`/installations/${item.id}/config`, '_blank')
        },
        duplicateInstallation(installation = null) {

            let installationsIds = this.$refs.innoCoreTable.$refs.installationsTable.selectedRows.map(item => item.id)
            if (installation) {
                installationsIds.push(installation.id)
            }
            this.$swal.fire({
                title: this.$installations_locale.global.duplicate_installation_title,
                html: getContent(installationsIds,this.getCustomers,this.duplicateContentTraslations()),
                showCancelButton: true,
                confirmButtonText: this.$installations_locale.global.buttons.success,
                cancelButtonText: this.$installations_locale.global.buttons.cancel,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    const copiesNumber = document.getElementById('copies-number').value;
                    const customerId = document.getElementById('customer-id').value;
                    const dependences = Array.from(document.querySelectorAll('.checkbox-dependence:checked')).map((checkbox) => checkbox.value);
                    return Promise.resolve({copiesNumber, customerId,dependences});
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('installations/copyInstallations', {
                        copies_number: result.value.copiesNumber,
                        installations_ids: installationsIds,
                        customer_id: result.value.customerId,
                        dependences:result.value.dependences
                    })
                    this.$refs.installationsTable.resetSelectedRows()
                }
            })
        },
        duplicateContentTraslations(){
            const trans = [
                {'duplicate_copies_number': this.$installations_locale.global.duplicate_copies_number},
                {'select_customer_id': this.$installations_locale.global.select_customer_id},
                {'select_duplicate_dependencies': this.$installations_locale.global.select_duplicate_dependencies},
                {'dependences': this.$installations_locale.global.dependences},
                {'copy': this.$installations_locale.global.copy},
                {'circuits':this.$installations_locale.global.circuits},
                {'accumulators':this.$installations_locale.global.accumulators},
                {'deposits':this.$installations_locale.global.deposits },
                {'zones':this.$installations_locale.global.zones },
                {'checkpoints':this.$installations_locale.global.checkpoints},
                {'consigns':this.$installations_locale.global.consigns},
                {'tasks':this.$installations_locale.global.tasks}
            ]
            const transObject = trans.reduce((acc, item) => {
                const key = Object.keys(item)[0]; // Obtener la clave del objeto
                const value = item[key]; // Obtener el valor del objeto
                acc[key] = value; // Asignar la clave y el valor al objeto acumulador
                return acc;
            }, {});
            return transObject
        }
    }

}
</script>
