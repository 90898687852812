<template>
    <card-template>
        <template slot="titleCard">{{ this.$readings_locale.global.readings_list }}</template>
        <div class="">
            <reading-table
                :user_admin="user_admin"
                :type="'ac'"
                :enableCreateBtn="false"
                :idTable="idTable"
                :columnsData="columns"
                :readingsId="readings_ids"
                :customFilters="customFilters"
                :rowItems="items"
                :customFields="customFields"
                :btnConfig="false"
                @edit="editItem"
                @delete="deleteItem"
            >
            </reading-table>
        </div>
        <modal-confirm-delete @confirmed="removeItemAc"/>
    </card-template>
</template>
<script>

export default{
    name: 'ReadingAccumulatorList',
    props:['readings_ids','user_admin'],
    created() {
        this.getReadingsAc()
    },
    data() {
        return {
            idTable: 'readingsTable',
            items: [],
            customFilters: [
                'date_range'
            ],
            customFields: [
            ],
            columns:[
                {
                    "field": "id",
                    "label": "#",
                    "display": "min_tabletP"
                },
                {
                    "field": "reading_date",
                    "label": this.$readings_locale.global.table_columns.reading_date
                },
                {
                    "field": "installation_name",
                    "label": this.$readings_locale.global.table_columns.installation
                },{
                    "field": "item_name",
                    "label": this.$readings_locale.global.table_columns.accumulator
                },

                {
                    "field": "options",
                    "label": this.$readings_locale.global.table_columns.detail
                }
            ]
        }
    },
    computed: {},
    methods: {
        getReadingsAc() {
            let queryParams = '';
            queryParams=`?readings_id=${JSON.stringify(this.readings_ids)}`
            axios.get(`/api-admin/readings/getReadings/ac/${queryParams}`).then(response => {
                const rows = response.data
                rows.map(item=>{
                    item.reading_date = moment(item.reading_date)
                })
                this.items = rows
            })
        },
        editItem(item) {
            window.location = `/readings/${item.id}`
        },
        deleteItem(item) {
            this.$swal.fire({
                title: this.$t('modals.confirm_delete.title'),
                html:  ` `,
                icon: 'warning',
                showCancelButton: true,
                // confirmButtonColor: translate('js_messages.buttons.bg'),
                // cancelButtonColor: translate('js_messages.confirm.cancelBtnColor'),
                confirmButtonText: this.$t('buttons.submit'),
                cancelButtonText: this.$t('buttons.cancel')
            }).then((result) => {
                if (result.isConfirmed) {
                    this.removeItemAc(item)
                }
            })
            // this.$bus.$emit('fireModalConfirmDelete', {
            //     text: ` `,
            //     parameters: item
            // })
        },
        removeItemAc(item) {
            axios.delete(`/api-admin/readings/${item.id}`).then(response => {
                this.$toast.success(this.$t('toast.delete_success'))
                this.getReadingsAc()
            })
        },
        newItem() {
            window.location = '/readings/create'
        },
        setItem(item){
            window.location = `/readings/${item.id}/config`
        }
    }

}
</script>

