export function getContent(tasksIds, transLocale) {

    const copyDependencesOptions = [
        'circuits',
        'accumulators',
        'deposits',
        'zones',
        'checkpoints',
        'consigns',
        'tasks'
    ];


    return `
    <div class="row justify-content-center m-0">
        <div class="col-12">
            <label>${transLocale.duplicate_copies_number}</label>
            <input  id="copies-number" value="1" type="number"  class="form-control swal2-input w-75 mt-0 mb-3" placeholder="${transLocale.duplicate_copies_number}">
            <label>${transLocale.duplicate_options_copy}</label>
            <div class="row my-3 justify-content-center">
                <div class="col-3">
                <button class="btn btn-primary btn-outline-primary active btn-option btn-days" data-option="days" @click="$('.option-selected').html('${transLocale.days.toLowerCase()}');$('.btn-option').removeClass('active').removeClass('btn-primary');$('.btn-days').addClass('active').addClass('btn-primary')">${transLocale.days}</button>
                </div>
                <input type="hidden" id="option-copy-selected">
                <div class="col-3"><button class="btn btn-outline-primary btn-option btn-months" data-option="months" @click="$('.option-selected').html('${transLocale.months.toLowerCase()}');$('.btn-option').removeClass('active').removeClass('btn-primary');$('.btn-months').addClass('active').addClass('btn-primary')">${transLocale.months}</button></div>
                <div class="col-3"><button class="btn btn-outline-primary btn-option btn-years"  data-option="years" @click="$('.option-selected').html('${transLocale.years.toLowerCase()}');$('.btn-option').removeClass('active').removeClass('btn-primary');$('.btn-years').addClass('active').addClass('btn-primary')">${transLocale.years}</button></div>
            </div>

            <label>${transLocale.amount_option_copy}  <span class="option-selected">${transLocale.days.toLowerCase()}</span> ${transLocale.to_add}</label>

            <input  id="option-selected-amount" value="1" type="number"  class="form-control swal2-input w-75 mt-0 mb-3" placeholder="${transLocale.amount_option_copy}">

        </div>
    </div>
`;
}
export function selectOption(el,option) {
    $('.option-selected').html(option.toLowerCase())
    $('.btn-option').removeClass('active').removeClass('btn-primary')
    $(el).addClass('active').addClass('btn-primary')
}
