<template>
    <div>
        <label>{{ label }}</label>
        <v-select @option:selected="add" @option:deselected="add" v-model="selected" :multiple="multiple" :options="items" label="name" code="id"></v-select>
    </div>
</template>
<script>
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css"

export default {
    name: 'CustomersCombo',
    props: {
        'label' : {type: String},
        'current': {type: Object},
        'multiple': {default: false}
    },
    components: {
        vSelect
    },
    created() {
        this.setData()
        this.selected = this.current ? {...this.current} : {}
    },
    watch: {
        current: function () {
            this.selected = {...this.current}
        },
        selected: function(){
          if(this.selected === null) this.add()
        }
    },
    data() {
        return {
            selected: {},
            items: []
        }
    },
    methods: {
        setData() {
            axios.get('/api-admin/customers/combo').then(response => {
                this.items = response
            })
        },
        add() {
            this.$emit('add', {...this.selected})
        }
    }
}
</script>
