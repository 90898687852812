import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'
import {InstallationStore,ZoneStore,CheckpointStore,CircuitStore} from '/../Modules/Installations/Resources/assets/store'
import {TaskStore} from "../../../Modules/Tasks/Resources/assets/store";
import {CustomerStore} from "../../../Modules/Customers/Resources/assets/store";

Vue.use(Vuex)


export default new Vuex.Store({
	modules:{
		installations: InstallationStore,
		zones: ZoneStore,
		checkpoints: CheckpointStore,
		tasks: TaskStore,
		circuits: CircuitStore,
		customers: CustomerStore,
	},
	plugins: [
		createPersistedState({
			storage: window.sessionStorage,
		}),
	],
})
