<template>
	<div class='progress-container' :style='{ height: height }'>

		<span class='d-block text-center' v-if='progress === 0'>
				<slot name='default-text'></slot>
		</span>

		<div
			class='progress-bar'
			:style='barStyles'
			:class='{ striped: striped, animated: striped && animated }'
		>

		<span
			class='progress-text'
			:style='{ fontSize: textSize }'
		>
		  <slot></slot>
      </span>
	</div>
	</div>
</template>

<script>
export default {
	props: {
		progress: {
			type: Number,
			required: true,
			validator: (value) => value >= 0 && value <= 100,
		},
		height: {
			type: String,
			default: '20px',
		},
		color: {
			type: String,
			default: '#4caf50',
		},
		backgroundColor: {
			type: String,
			default: '#e0e0e0',
		},
		borderRadius: {
			type: String,
			default: '10px',
		},
		striped: {
			type: Boolean,
			default: false,
		},
		textSize: {
			type: String,
			default: '14px',
		},
		animated: {
			type: Boolean,
			default: true,
		},
		delay: {
			type: String,
			default: '1',
		},
	},
	computed: {
		barStyles() {
			const lightColor = this.lightenColor(this.color, 0.15) // Calcula el color más claro
			return {
				width: `${this.progress}%`,
				backgroundColor: this.color,
				transition: this.animated ? `width ${this.delay}s ease` : 'none',
				height: '100%',
				borderRadius: this.borderRadius,
				backgroundImage: this.striped
					? `linear-gradient(
              45deg,
              ${lightColor} 25%,  /* Usa el color más claro aquí */
              rgba(255, 255, 255, 0.2) 25%,
              rgba(255, 255, 255, 0.2) 50%,
              ${lightColor} 50%,
              ${lightColor} 75%,
              rgba(255, 255, 255, 0.2) 75%,
              rgba(255, 255, 255, 0.2) 100%
            )`
					: 'none',
				backgroundSize: this.striped ? '20px 20px' : 'auto',
			}
		},


	},
	methods: {
		// Función para aclarar el color
		lightenColor(color, percent) {
			// Asegúrate de que el color es un string
			if (typeof color !== 'string') {
				return color // Devuelve el color original si no es un string
			}
			const num = parseInt(color.replace(/^#/, ''), 16)
			const amt = Math.round(2.55 * percent * 100)
			const R = (num >> 16) + amt
			const G = (num >> 8 & 0x00FF) + amt
			const B = (num & 0x0000FF) + amt
			return `#${(0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 + (B < 255 ? (B < 1 ? 0 : B) : 255)).toString(16).slice(1)}`
		},
	},
}
</script>

<style scoped>
.progress-container {
	width: 100%;
	background-color: var(--background-color, #e0e0e0);
	border-radius: var(--border-radius, 10px);
	overflow: hidden;
	position: relative;
}

.progress-bar {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	white-space: nowrap;
	text-align: center;
	position: relative;
}

.progress-bar.striped {
	background-color: transparent;
}

.progress-bar.striped.animated {
	animation: move-stripes linear infinite;
}

.progress-text {
	position: absolute;
	width: 100%;
	text-align: center;
	z-index: 1;
	pointer-events: none;
}

/* Animación de las rayas */
@keyframes move-stripes {
	from {
		background-position: 0 0;
	}
	to {
		background-position: 80px 80px;
	}
}
</style>
