export const getReports = (context) => {

	axios.get('/api-admin/reports').then(response => {
		context.commit('setReports', response.data)
	})
}

export const deleteReport = (context, id) => {
	return axios.delete(`/api-admin/reports/${id}`).then(response => {
		getReports(context)
	})
}

export const getConfigReports = (context) => {
	axios.get('/api-admin/reports/config/reports').then(response => {
		context.commit('setConfigReports', response)
	})
}

export const saveReport = (context, report) => {
	return axios.post('/api-admin/reports', report).then(response => {
		getReports(context)
	})
}

export const generateReport = (context, {report,params}) => {
	return axios.post(`/api-admin/reports/generate/${report.id}`,{params})
}

export const getReport = (context, id) => {
	const filter = context.state.filter
	const params = window.$helpers.setUrlParameters(filter)
	return axios.get(`/api-admin/reports/${id}/${params}`).then(response => {
		context.commit('setCurrentReport', response.data)
	})
}

export const deleteFile = (context, payload) => {
	return axios.delete(`/api-admin/reports/file/${payload.id}`)
}