<template>
	<v-select
		:options='attributes'
		:multiple='multiple'
		v-model='current'
		label='label'
		code='id'
		@input='emitSelected'
	></v-select>
</template>
<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
	name: 'Attributes',
	components: {
		vSelect,
	},
	props: {
		setted: {type: Array},
		multiple: {default: false},
	},
	created() {
		this.getAttributes()
	},
	watch: {
		setted: function() {
			this.filterAttributes()
		},
	},
	data() {
		return {
			attributes: [],
			allAttributes: [],
			current: null,
		}
	},
	methods: {
		filterAttributes() {
			const setted_ids = this.setted ? this.setted.map(attribute => attribute.reading_key_id) : []
			console.log({all: this.allAttributes, setted: setted_ids})
			this.current = this.allAttributes.filter(attr => setted_ids.includes(attr.id))
			this.attributes = this.allAttributes.filter(attr => !setted_ids.includes(attr.id))
		},
		getAttributes() {
			const url = '/api-admin/readings/attributes'
			axios.get(url).then(response => {
				const attributes_sorted = response.data.sort(this.$helpers.orderArrayObjects('label'))
				this.attributes = response.data
				this.allAttributes = response.data
				this.filterAttributes()
			})
		},
		emitSelected() {
			this.$emit('selected', this.current)
		},
	},
}
</script>