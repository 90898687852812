export const setCurrent = (state,payload)=> {
	state.current = payload
}

export const removeCurrent = (state) => {
	state.current = null
}

export const setCurrentInstallationId = (state,payload)=> {
	state.current_installation_id = payload
}

export const setCurrentZoneId = (state,payload)=> {
	state.current_zone_id = payload
}

export const setCheckpoints = (state,payload)=> {
	state.checkpoints = payload
}