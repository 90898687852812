<template>
    <div class="table-responsive">
        <analysis-datatable
            ref="analysisDatatable"
            :enableCreateBtn="true"
            :idTable="idTable"
            :search="search"
            :columnsData="columns"
            :rowItems="items_analysis"
            :customFields="customFields"
            :customFilters="customFilters"
            :btnConfig="false"

        ></analysis-datatable>
    </div>
</template>

<script>
import AnalysisDatatable from "./AnalysisDatatable";
export default {
    name: "AnalysisList",
    components: {AnalysisDatatable},
    props:{
      items_analysis: { required:true,default:[] }
    },
    created() {
      this.items = this.items_analysis
    },
    watch: {
      item_analysis() {
          this.items = this.items_analysis
      }
    },
    data() {
        return {
            idTable: 'analysisTable',
            items: [],
            search: '',
            customFields: [

            ],
            customFilters:[

            ],
            columns: [
                {
                    field: 'id',
                    label: '#',
                    display: 'min_tabletP',
                },
                {
                    field: 'format_date',
                    label: this.$readings_locale.global.table_columns.reading_date,
                },
                {
                    field: 'circuit_name',
                    label: this.$readings_locale.global.table_columns.circuit,
                },
                {
                    field: 'checkpoint_name',
                    label: this.$readings_locale.global.table_columns.checkpoint,
                    className:'checkpoint_name_cell'
                },

                {
                    field: 'legionella_analysis',
                    label: this.$readings_locale.global.table_columns.legionella_analysis,
                },
                {
                    field: 'ac_temperature',
                    label: this.$readings_locale.global.table_columns.temperature_ac,
                },
                {
                    field: 'af_temperature',
                    label: this.$readings_locale.global.table_columns.temperature_af,
                },
                {
                    field: 'comments',
                    label: this.$readings_locale.global.table_columns.comments/*this.$tasks_locale.global.table_columns.customer*/,

                }
            ]
        }
    }
}
</script>

<style lang="scss">


</style>
