<template>
    <card-template>

        <div class="row">
            <div class="col-sm-4 mb-3">
                <img class="img-fluid avatar mb-3" :src="image_path">
                <div class="mb-3">
                    <div class="custom-file">
                        <input type="file" @change="setFile" class="custom-file-input" id="file">
                        <label class="custom-file-label" for="file">{{ fileName }}</label>
                    </div>
                </div>
            </div>
            <div class="col-sm-8 mb-3">
                <div class="col-sm-12 mb-3">
                    <label for="customerCode" class="required">{{ this.$customers_locale.global.code }}</label>
                    <input type="text" v-model="formData.customer_code" id="customerCode" class="form-control">
                </div>
                <div class="col-sm-12 mb-3">
                    <label for="name" class="required">{{ this.$customers_locale.global.name }}</label>
                    <input type="text" v-model="formData.name" id="name" class="form-control">
                </div>
            </div>

            <div class="col-sm-4 mb-3">
                <label for="cif">{{ this.$customers_locale.global.cif }}</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span id="cifIcon" class="input-group-text"><i class="fa fa-file-upload"></i></span>
                    </div>
                    <input aria-describedby="cifIcon" type="text" id="cif" name="cif" class="form-control" v-model="formData.cif">
                </div>
            </div>

            <div class="col-sm-4 mb-3">
                <label for="phone">{{ this.$customers_locale.global.phone }}</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span id="phoneIcon" class="input-group-text"><i class="fa fa-phone"></i></span>
                    </div>
                    <input aria-describedby="phoneIcon" type="phone" id="phone" name="phone" class="form-control" v-model="formData.phone">
                </div>
            </div>

            <div class="col-sm-4 mb-3">
                <label for="cif">{{ this.$customers_locale.global.contact }}</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span id="contactIcon" class="input-group-text"><i class="fa fa-user"></i></span>
                    </div>
                    <input aria-describedby="contactIcon" type="text" id="contact" name="contact" class="form-control" v-model="formData.contact">
                </div>
            </div>

            <div class="col-md-6 mb-3">
                <label for="address">{{ this.$customers_locale.global.address }}</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span id="addressIcon" class="input-group-text"><i class="fa fa-building"></i></span>
                    </div>
                    <input aria-describedby="addressIcon" type="text" id="address" name="address" class="form-control" v-model="formData.address">
                </div>
            </div>

            <div class="col-md-6 mb-3">
                <label for="email">{{ this.$customers_locale.global.email }}</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span id="emailIcon" class="input-group-text"><i class="fa fa-envelope"></i></span>
                    </div>
                    <input aria-describedby="emailIcon" type="email" id="email" name="email" class="form-control" v-model="formData.email">
                </div>
            </div>
        </div>

        <hr>

        <button @click="save" class="btn btn-primary">{{ this.$customers_locale.global.buttons.save }}</button>

    </card-template>

</template>
<script>

export default {
    name: 'CustomerForm',
    props: ['customer_id'],
    components: {},
    created() {
        if (this.customer_id) {
            this.getItem()
        }
    },
    data() {
        return {
            fileName: this.$customers_locale.global.choose_file,
            image_path: '/images/no-image.jpg',
            formData: {
                id: null,
                name: null,
                phone: null,
                email: null,
                cif: null,
                address: null,
                customer_code: null,
                image: '',
                contact: '',
            },
        }
    },
    methods: {
        getItem() {
            axios.get(`/api-admin/customers/${this.customer_id}`).then(response => {
                const item = response.data
                this.formData.name = item.name
                this.formData.customer_code = item.customer_code
                this.formData.id = item.id
                this.formData.phone = item.phone
                this.formData.address = item.address
                this.formData.cif = item.cif
                this.formData.email = item.email
                this.image_path = item.avatar
                this.formData.contact = item.contact
            })
        },

        setFile(e) {
            const file = e.target.files[0]
            this.fileName = file.name
            this.formData.image = file
        },

        save() {
            let formData = new FormData()
            Object.keys(this.formData).map(field => {
                formData.append(field, this.formData[field]===null ? '' : this.formData[field])
            })


            axios.post('/api-admin/customers', formData, {headers: {'content-type': 'multipart/form-data'}}).then(response => {
                this.$helpers.showLoading()
                this.$helpers.toastSuccess()
                setTimeout(() => {
                    window.location = `/customers/${response.data.id}`
                }, 1000)
            })
        }
    }
}
</script>
<style lang="css" scoped>
.custom-file-input:lang(en) ~ .custom-file-label:after {
    content: "\f093"; /* inspect an icon for this value */
    font-family: FontAwesome; /* intentionally no fallback */
    font-size: 16px;
}

.avatar {
    border-radius: 10px;
    border: solid 1px #ccc;
    padding: 10px;
}
</style>
