import {setEditedAcc, setSelectedAcc} from "./mutations";

export const copyAccumulators = (context,payload) => {
    return axios.post(`/api-admin/installations/accumulators/copy`, payload).then(response => {
        context.commit('setSelectedAcc', [])
        window.toast.success(window.trans_installations.global.accumulators_copied_success)
        return response
    }).catch(err=>{
        window.toast.error(window.trans_installations.global.accumulators_copied_error)
        return err
    });
}
export const copyDeposits = (context,payload) => {
    return axios.post(`/api-admin/installations/deposits/copy`, payload).then(response => {
        context.commit('setSelectedDeposit', [])
        window.toast.success(window.trans_installations.global.deposits_copied_success)
        return response
    }).catch(err=>{
        window.toast.error(window.trans_installations.global.deposits_copied_error)
        return err
    });
}
export const getCircuits = (context,installation_id) => {
    return axios.get(`/api-admin/installations/${installation_id}/circuits`).then(response => {
        const rows = response.data
        context.commit('setCircuits',rows)
    })
}
export const saveEditedDeposits = (context,installation_id) => {
    const editedDeposits = context.state.edited_deposits
    return axios.post(`/api-admin/installations/${installation_id}/deposit/save`,{editedDeposits}).then(response => {
        window.toast.success(window.trans_installations.global.deposit_modified_success)
        context.commit('setEditedDeposit',[])
        context.commit('setActiveDepositEdition',false)
        return response

    }).catch(error=>{
        window.toast.success(window.trans_installations.global.deposit_modified_error)
        return error

    })
}

export const saveEditedAccumulators = (context,installation_id) => {
    const editedAcc = context.state.edited_acc
    return axios.post(`/api-admin/installations/${installation_id}/acc/save`,{editedAcc}).then(response => {
        window.toast.success(window.trans_installations.global.accumulators_modified_success)
        context.commit('setEditedAcc',[])
        context.commit('setActiveAccumulatorsEdition',false)
        return response

    }).catch(error=>{
        window.toast.success(window.trans_installations.global.accumulators_modified_error)
        return error

    })
}
