export default {
	reports:[],
	show_form_modal:false,
	show_gen_report_modal:false,
	current_report:null,
	config_reports:[],
	filter:{
		installation_id:null,
		customer_id: null
	}
}