<template>
    <h6 class="m-0 font-weight-bold text-primary mb-3 d-inline">
        <input type="checkbox" class="form-check-inline cursor-pointer zone_check" style="width: 20px;height: 20px"
               value="1"
               @input="setSelectedZoneItems($event,zone.item.id)" @click="selectZoneItem($event)">
        <small class="font-bold font-italic">#{{ zone.item.id }}</small>
        <!--        <small class="btn btn-xs mr-1 btn-dark">{{zone.item.code}}</small> - -->
        <div v-if="getActiveZonesEdition" class="input-edit-zone" style="display: inline-block">
            <input type="text" class="form-control"  @input="setEditedZone(zone.item.id,zone.item.name)" style="display: inline-block;width: 400px" v-model="zone.item.name" >
        </div>
        <span v-else="getActiveZonesEdition">{{ zone.item.name }}</span>
        <button @click="deleteItem(zone.item)" class="ml-1 btn btn-danger btn-xs float-right">
            <i class="fa fa-trash"></i></button>
        <button @click="editZone(zone.item)" class="ml-1 btn btn-warning btn-xs float-right">
            <i class="fa fa-pencil"></i></button>

        <button v-if="!zone.item.wet_zone" @click="newZone" class="ml-1 btn btn-success btn-xs float-right">
            <i class="fa fa-plus"></i></button>

        <button v-else @click='editCheckpoints(zone.item)' class='btn btn-secondary btn-xs float-right ml-1'>
            <i class="fa fa-tachometer"></i>
        </button>
        <button @click='duplicateZone($event,zone.item)' class='btn btn-info btn-xs float-right ml-1'>
            <i class="fa fa-copy"></i>
        </button>
        <br>
        <small class="mt-2 d-block text-gray-600">
            <span class="btn btn-xs btn-info">{{ zone.item.type.name }}</span> <i v-for="(path) in zone.path.split('/')"
                                                                                  class="btn btn-xs btn-secondary mr-1">{{
                path
            }}</i>
        </small>
        <small class="mt-2 d-block text-gray-600">
            <span class="btn btn-xs btn-info">{{ this.$installations_locale.global.circuits }}</span> <i
            :key="circuit.id"
            v-for="(circuit) in zone.item.circuits"
            class="btn btn-xs btn-secondary mr-1">{{
                circuit.code
            }}</i>
        </small>
    </h6>
</template>
<script>
import {mapGetters} from 'vuex'
import {copyZone} from "../../store/zones/actions";
import {getEditedZones} from "../../store/zones/getters";

export default {
    name: 'ZoneItem',
    props: {
        installation_id: {required: true},
        zone: {required: true},
        edit_fn: {required: false, type: Function},
        refresh_fn: {required: true, type: Function},
    },
    computed: {
        ...mapGetters('zones', ['newZoneUrl', 'editZoneUrl', 'getZones', 'getSelectedZones', 'getActiveZonesEdition','getEditedZones']),
        ...mapGetters('checkpoints', ['listCheckpointUrl'])
    },
    data() {
        return {
            selectedZones: []
        }
    },
    methods: {
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDeleteFn', {
                text: `${this.$installations_locale.global.modals.delete_zone} ${item.name} `,
                parameters: item,
                fn: this.removeItem
            })
        },
        removeItem(item) {
            const url = `/api-admin/installations/${this.installation_id}/zones/${item.id}`
            axios.delete(url).then(response => {
                this.$toast.success(this.$t('toast.delete_success'))
                this.refresh_fn(response)
            })
        },
        newZone(e) {
            e.preventDefault()
            // window.location.href = this.newZoneUrl + '?parent=' + this.zone.item.id
            window.open(this.newZoneUrl + '?parent=' + this.zone.item.id, '_blank')
        },
        editZone(item) {
            this.$store.commit('zones/setCurrent', item);
            window.open(this.editZoneUrl, '_blank')
            // window.location.href = this.editZoneUrl
        },
        editCheckpoints(zone) {
            this.$store.commit('zones/setCurrent', zone);
            window.open(this.listCheckpointUrl, '_blank')
        },
        duplicateZone(e, zone) {
            e.stopPropagation()
            this.$swal.fire({
                title: this.$installations_locale.global.copies_number_quest,
                input: 'number',
                inputValue: '1',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: this.$installations_locale.global.buttons.success,
                cancelButtonText: this.$installations_locale.global.buttons.cancel,
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !this.$swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('zones/copyZones', {
                        zone_id: [zone.id],
                        copies_number: result.value,
                        installation_id: zone.installation_id
                    })
                    $('.zone_check').map((index, item) => {
                        if (item.checked) {
                            item.checked = false
                        }
                    })
                }
            })
        },
        selectZoneItem(e) {
            e.stopPropagation()
        },
        setSelectedZoneItems(e, zoneId) {
            let selectedZones = this.getSelectedZones
            if (e.target.checked) {
                selectedZones.push(zoneId)
            } else {
                const unselectedZone = selectedZones.findIndex(item => item == zoneId)
                selectedZones.splice(unselectedZone, 1)
            }
            this.$store.commit('zones/setSelectedZones', selectedZones)
        },
        setEditedZone(zoneId,zoneName){
            let editedZones = this.getEditedZones
            let checkExist = null
            if(editedZones.length > 0){
                checkExist = editedZones.find(item => item.id == zoneId)
            }
            if(checkExist){
                checkExist.name = zoneName
            }else{
                editedZones.push({id:zoneId,name:zoneName})
            }
        }

    }
}
</script>
