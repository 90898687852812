export const setCurrent = (state,payload)=> {
	state.current = payload
}

export const removeCurrent = (state) => {
	state.current = null
}
export const setZones = (state,payload)=> {
    state.zones = payload
}
export const addZones = (state,payload)=> {
    Object.keys(payload).forEach(index=>{
        state.zones[index] = payload[index]
    })
}
export const setSelectedZones = (state,payload)=>{
	state.selected_zones = payload
}
export const setActiveZonesEdition = (state,payload)=>{
	state.active_zones_edition = payload
}
export const setEditedZones = (state,payload)=>{
    state.edited_zones = payload
}
export const setActiveCheckpointsEdition = (state,payload)=>{
	state.active_checkpoints_edition = payload
}
export const setEditedCheckpoints = (state,payload)=>{
	state.edited_checkpoints = payload
}
