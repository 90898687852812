<template>
	<div>
		<v-client-table :ref='idTable' :data='items' :columns='columns' :options='options'>
			<div slot='afterFilter'>
				<checkpoint-filter @setFilter='setFilter' :current='currentFilter' class='mx-2 my-2' :installation_id='installation_id' :zone_id='zone_id' :refresh_fn='refreshData'></checkpoint-filter>

			</div>
			<div slot='afterFilter'>
				<a v-if='!getActiveCheckpointsEdition' v-show="$helpers.hasPermission('checkpoints.edit')" type='button' @click='editCheckpoints($event,installation_id)' class='btn btn-warning btn-sm'><i
					class='fa fa-pencil'></i>
					{{ this.$installations_locale.global.active_checkpoint_edition }}
				</a>
				<a v-if='getActiveCheckpointsEdition' v-show="$helpers.hasPermission('checkpoints.edit')" type='button' @click='saveCheckpointsEdited($event,installation_id)' class='btn btn-primary btn-sm'><i
					class='fa fa-save'></i>
					{{ this.$installations_locale.global.buttons.save }}
				</a>
				<a v-if='getActiveCheckpointsEdition' v-show="$helpers.hasPermission('checkpoints.edit')" type='button' @click='editCheckpoints($event,installation_id)' class='btn btn-warning btn-sm'><i
					class='fa fa-times'></i>
					{{ this.$installations_locale.global.buttons.cancel }}
				</a>
			</div>
			<div slot='beforeTable'>
				<div class='text-right'>
					<button @click='newItem' class='btn btn-primary btn-sm mb-2'><i class='fa fa-plus'></i>
						{{ this.$t('buttons.new') }}
					</button>
				</div>
			</div>
			<div slot='client_code' slot-scope='{row}'>
				<div v-if='getActiveCheckpointsEdition' class='input-edit-checkpoint' style='display: inline-block'>
					<input type='text' class='form-control' @input='setEditedCheckpoint(row.id,row.client_code)' style='display: inline-block;' v-model='row.client_code'>
				</div>
				<span v-else>{{ row.client_code }}</span>
			</div>

			<div slot='ac_icon' slot-scope='{row}' class='text-center' v-html='row.ac_icon'></div>
			<div slot='af_icon' slot-scope='{row}' class='text-center' v-html='row.af_icon'></div>
			<div slot='mix_icon' slot-scope='{row}' class='text-center' v-html='row.mix_icon'></div>

			<template #options='{row}'>
				<button @click='editItem(row)' v-show="$helpers.hasPermission('checkpoints.edit')" class='btn btn-xs btn-warning'>
					<i class='fa fa-pencil'></i></button>
				<button @click='deleteItem(row)' v-show="$helpers.hasPermission('checkpoints.delete')" class='btn btn-xs btn-danger'>
					<i class='fa fa-trash'></i></button>
				<button v-if='btnConfig' @click='config(row)' class='btn btn-xs btn-primary'><i class='fa fa-cog'></i>
				</button>
				<button :title='restore_label' v-if='row.disabled === true' @click='restore(row)' class='btn btn-xs btn-info'>
					<i class='fa fa-sync'></i></button>
				<button @click='duplicateCheckpoint(row)' class='btn btn-info btn-xs ml-1'>
					<i class='fa fa-copy'></i>
				</button>
			</template>

		</v-client-table>
	</div>
</template>
<script>
import CheckpointFilter from './CheckpointsFilter'
import {mapGetters} from 'vuex'
import {setEditedCheckpoints} from '../../store/zones/mutations'
import {getEditedCheckpoints} from '../../store/zones/getters'


export default {
	name: 'CheckpointsTable',
	props: {
		btnConfig: {default: false},
		columnsData: {required: true},
		idTable: {required: true},
		rowItems: {required: true},
		customFields: {required: false},
		enableCreateBtn: {default: true},
		installation_id: {required: true},
		zone_id: {required: true},
	},
	computed: {
		restore_label: function() {
			return this.$installations_locale.global.restore_checkpoint
		},
		...mapGetters('zones', ['getActiveCheckpointsEdition', 'getEditedCheckpoints']),

	},

	components: {
		CheckpointFilter,
	},
	created() {
		this.setColumns()
		this.items = [...this.rowItems]
		this.$store.commit('zones/setActiveCheckpointsEdition', false)

		this.$store.commit('zones/setEditedCheckpoints', [])
	},
	watch: {
		rowItems: function(newItems) {
			this.items = [...newItems]
		},
	},
	data() {
		return {
			items: [],
			columns: [],
			currentFilter: null,
			options: {
				rowClassCallback: row => {
					return row.disabled ? 'disabled-item' : null
				},
				resizableColumns: true,
				perPage: 10,
				perPageValues: [5, 10, 50, 100],
				pagination: {chunk: 5},
				datepickerOptions: {
					showDropdowns: true,
					autoUpdateInput: true,
				},
				sortIcon: {
					base: 'fa float-right',
					is: 'fa-sort',
					up: 'fa-sort-up',
					down: 'fa-sort-down',
				},
				texts: {
					count: `${this.$t('Showing {from} to {to} of {count} records|{count} records|One record')}`,
					first: `${this.$t('First')}`,
					last: `${this.$t('Last')}`,
					filter: `${this.$t('Filter')}:`,
					filterPlaceholder: `${this.$t('Search query')}`,
					limit: `${this.$t('Records')}:`,
					page: `${this.$t('Page')}:`,
					noResults: `${this.$t('No matching records')}`,
					filterBy: `${this.$t('Filter by {column}')}`,
					loading: `${this.$t('Loading')}...`,
					defaultOption: `${this.$t('Select {column}')}`,
					columns: `${this.$t('Columns')}`,
				},
				columnsDisplay: {},
				// columnsDropdown: true,
				// columnsDisplay: {
				//     email: 'min_tabletP',
				//     id: 'min_tabletP'
				// },
				// selectable:{
				//     mode: 'multiple', // or 'multiple'
				//     only: function(row) {
				//         return true // any condition
				//     },
				//     selectAllMode: 'all', // or 'page',
				//     programmatic: false
				// }
			},
		}
	},
	methods: {
		setEditedCheckpoints,
		setColumns() {
			const headings = {}
			this.columnsData.map(item => {
				this.columns.push(item.field)
				headings[item.field] = item.label
				if (item.display) {
					this.options.columnsDisplay[item.field] = item.display
				}
			})
			this.options.headings = headings

		},

		refreshData(items) {
			this.items = items
		},

		editItem(item) {
			this.$emit('edit', item)
		},
		deleteItem(item) {
			this.$emit('delete', item)
		},
		newItem() {
			this.$emit('new')
		},
		config(item) {
			this.$emit('config', item)
		},

		setFilter(filter) {
			this.currentFilter = filter
			this.filterResults()
		},

		filterResults() {
			const filter = this.currentFilter ? {checkpoint_status: this.currentFilter.key} : {}
			const queryParams = this.$helpers.setUrlParameters(filter)
			const url = `/api-admin/installations/${this.installation_id}/checkpoints/zone/${this.zone_id}${queryParams}`
			axios.get(url).then(response => {
				const rows = response.data
				this.refreshData(rows)
			})
		},

		restore(item) {
			const url = `/api-admin/installations/${this.installation_id}/checkpoints/${item.id}/zone/${this.zone_id}/restore`
			axios.post(url).then(response => {
				this.filterResults()
			})
		},
		editCheckpoints() {
			this.$store.commit('zones/setActiveCheckpointsEdition', !this.getActiveCheckpointsEdition)

		},
		saveCheckpointsEdited() {
			this.$store.dispatch('zones/saveEditedCheckpoints', this.installation_id)
		},
		setEditedCheckpoint(checkpointId, checkpointCode) {
			let editedCheckpoints = this.getEditedCheckpoints
			let checkExist = null
			if (editedCheckpoints.length > 0) {
				checkExist = editedCheckpoints.find(item => item.id == checkpointId)
			}
			if (checkExist) {
				checkExist.client_code = checkpointCode
			} else {
				editedCheckpoints.push({id: checkpointId, client_code: checkpointCode})
			}
		},
		duplicateCheckpoint(checkpoint) {
			this.$swal.fire({
				title: this.$t('copies_number_quest'),
				input: 'number',
				inputValue: '1',
				inputAttributes: {
					autocapitalize: 'off'
				},
				showCancelButton: true,
				confirmButtonText: this.$t('buttons.success'),
				cancelButtonText: this.$t('buttons.cancel'),
				showLoaderOnConfirm: true,
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then(async (result) => {
				if (result.isConfirmed) {
					await this.$store.dispatch('checkpoints/duplicate', {
						copies_number: Number(result.value),
						checkpoint_id: checkpoint.id,
					})
					this.$helpers.toastSuccess()
				}
			})
		}
	},

}
</script>
<style lang='scss'>

tr.VueTables__row.disabled-item {
	background-color: #ffe5e5 !important;
}

.VueTables {
	label {
		margin-right: 10px;
	}
}

.VueTables__wrapper {
	max-height: 500px;
	overflow-y: scroll;
}

.VueTables__search-field {
	display: flex;
}

.VueTables__limit-field {
	display: flex;
}

.VueTables__columns-dropdown-wrapper {
	margin-right: 10px;
}

.VueTables__row {
	td {
		padding: 3px 5px;
		vertical-align: middle;
	}
}

.VueTables__columns-dropdown {
	input {
		margin-right: 3px;
	}
}
</style>
