<template>
	<div>
		<h4>Grado de cumplimiento de la tarea</h4>
			<label>Rango de fechas a consultar el % de cumplimiento</label>
			<DatePickerComponent :showLabel="false" @range='setRange'  />
			<div  class='mt-2'>
				<ProgressBar
					textSize="1rem"
					height='25px'
					delay='1'
					:striped='compliance < 100'
					:progress="compliance"
					:color="compliance < 100 ? '#ebb734' : '#4caf50' "
				>
					<template #default-text>
						<span class='primary'>
							No se ha encontrado ninguna lectura
						</span>
					</template>

					<span class='text-dark'>
						{{ compliance }}%
					</span>

				</ProgressBar>
			</div>
		</div>
</template>

<script>
import DatePickerComponent from '@/DatePickerComponent.vue'
// import VueJsProgress from 'vue-js-progress'
import ProgressBar from '@/ProgressBar.vue'
export default {
	name: 'TaskComplianceReport',
	components: {
		DatePickerComponent,
		ProgressBar,
	},
	props: {
		task_id: {required: true},
	},
	created(){
		this.getData()
	},
	data() {
		return {
			range: null,
			compliance: 0
		}
	},
	methods: {
		setRange(payload) {
			this.range = payload.time[0] ? payload.time : null
			this.getData()
		},
		getData() {
				const params= this.$helpers.setUrlParameters({range:this.range})
				axios.get(`/api-admin/tasks/${this.task_id}/report/compliance/${params}`).then(response => {
					this.compliance = parseFloat(response)
				})
		},
	},
}
</script>